import classNames from 'classnames';
import classes from './ExternalLink.module.scss';
import { ButtonText } from '@ids-react/button';

export interface ExternalLinkProps {
  url: string;
  buttonText: string;
}

const ExternalLink = ({ url, buttonText }: ExternalLinkProps): JSX.Element => {
  return (
    <div className={classNames(classes.ExternalLink)}>
      <ButtonText
        style={{
          height: '1.9375rem',
          marginBottom: '0.75rem',
          paddingLeft: '1.3125rem',
          fontSize: '0.875rem',
        }}
        onClick={() => {
          window.open(url, '_blank', 'noopener,noreferrer');
        }}
      >
        {buttonText}
      </ButtonText>
    </div>
  );
};

export default ExternalLink;
