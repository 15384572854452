import {
  BaseNodeProperty,
  NodeProperty,
  SummaryNode,
} from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class SummaryLayout extends BaseLayout {
  constructor(node: SummaryNode) {
    super(node);

    const { properties } = node;
    const { fileName, summaryDefinition, pageHeightInMillimeters } =
      NodeProperty;
    const { message, variableName } = BaseNodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties[message].label,
          placeholder: baseNodeProperties[message].placeholder,
          ...properties[message],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: summaryDefinition,
          label: nodeProperties[summaryDefinition].label,
          placeholder: nodeProperties[summaryDefinition].placeholder,
          ...properties[summaryDefinition],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: fileName,
          label: nodeProperties[fileName].label,
          ...properties[fileName],
        },
        this.mappedFields
      );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.propertiesTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextField(
        {
          fieldName: pageHeightInMillimeters,
          label: nodeProperties[pageHeightInMillimeters].label,
          ...properties[pageHeightInMillimeters],
        },
        this.mappedFields
      );

    this.addCommentField();
    this.addAccessLogDataGroup();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default SummaryLayout;
