import {
  BaseNodeProperty,
  InputDateNode,
  NodeProperty,
} from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class InputDateLayout extends BaseLayout {
  constructor(node: InputDateNode) {
    super(node);

    const { properties } = node;
    const {
      confirmButton,
      datePreset,
      dateAvailabilityType,
      minimumPeriod,
      maximumPeriod,
    } = NodeProperty;
    const { message, variableName, validationMessage } = BaseNodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties[message].label,
          placeholder: baseNodeProperties[message].placeholder,
          ...properties[message],
        },
        this.mappedFields
      )
      .addDropdownField(
        {
          fieldName: datePreset,
          label: nodeProperties[datePreset].label,
          ...properties[datePreset],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: confirmButton,
          label: nodeProperties[confirmButton].label,
          placeholder: nodeProperties[confirmButton].placeholder,
          ...properties[confirmButton],
        },
        this.mappedFields
      )
      .addDefaultValueField(node, this.mappedFields);

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.validationTab
      .addGroup(nodePropertiesGroups.availableDates)
      .addTextField(
        {
          fieldName: minimumPeriod,
          label: nodeProperties[minimumPeriod].label,
          placeholder: nodeProperties[minimumPeriod].placeholder,
          ...properties[minimumPeriod],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: maximumPeriod,
          label: nodeProperties[maximumPeriod].label,
          placeholder: nodeProperties[maximumPeriod].placeholder,
          ...properties[maximumPeriod],
        },
        this.mappedFields
      )
      .addDropdownField(
        {
          fieldName: dateAvailabilityType,
          label: nodeProperties[dateAvailabilityType].label,
          ...properties[dateAvailabilityType],
        },
        this.mappedFields
      );

    this.validationTab
      .addGroup(nodePropertiesGroups.validationMessage)
      .addTextAreaField(
        {
          fieldName: validationMessage,
          label: baseNodeProperties[validationMessage].label,
          placeholder: baseNodeProperties[validationMessage].placeholder,
          ...properties[validationMessage],
        },
        this.mappedFields
      );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.addAccessLogDataGroup();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default InputDateLayout;
