import {
  BaseNodeProperty,
  NodeProperty,
  SetStatisticsNode,
} from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class SetStatisticsLayout extends BaseLayout {
  constructor(node: SetStatisticsNode) {
    super(node);

    const { properties } = node;
    const { variableName, message } = BaseNodeProperty;

    const { displayRecorded, recordNodeValueBag, statisticsEventType } =
      NodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties[message].label,
          placeholder: baseNodeProperties[message].placeholder,
          ...properties[message],
        },
        this.mappedFields
      );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.propertiesTab
      .addGroup()
      .addCheckboxField(
        {
          fieldName: displayRecorded,
          label: nodeProperties[displayRecorded].label,
          ...properties[displayRecorded],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: recordNodeValueBag,
          label: nodeProperties[recordNodeValueBag].label,
          placeholder: nodeProperties[recordNodeValueBag].placeholder,
          ...properties[recordNodeValueBag],
        },
        this.mappedFields
      )
      .addDropdownField(
        {
          fieldName: statisticsEventType,
          label: nodeProperties[statisticsEventType].label,
          ...properties[statisticsEventType],
        },
        this.mappedFields
      );

    this.addCommentField();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default SetStatisticsLayout;
