import axios, { AxiosError, CancelTokenSource } from 'axios';
import { useState } from 'react';
import type { ApiResponse } from '@/api/types';

export interface UseCallApiReturn<T, P> {
  requestData: T | null;
  isRequestInProgress: boolean;
  isRequestCanceled: boolean;
  isRequestSuccessful: boolean;
  requestError: AxiosError | null;
  makeRequest: (apiMethodParams?: P) => Promise<void>;
  cancelPreviousRequest: (cancelMessage: string) => void;
}

/**
 * Handles provided async network request method
 *
 * @template T The `requestData` type the provided request promise will resolve to
 * @template P The parameter type the `makeRequest` promise expects
 * @param {(apiMethodParams: P) => Promise<ApiResponse<T>>} apiMethod API request method reference
 * @param {number} [delayAfterResolveInMS=0] Delay between the request resolving and resetting `isRequestInProgress`
 * @return {*} {UseCallApi<T, P>}
 */
const useCallApi = <T, P>(
  apiMethod: (apiMethodParams: P) => Promise<ApiResponse<T>>,
  delayAfterResolveInMS = 0
): UseCallApiReturn<T, P> => {
  const [requestData, setRequestData] = useState<T | null>(null);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [isRequestCanceled, setIsRequestCanceled] = useState(false);
  const [isRequestSuccessful, setIsRequestSuccessful] = useState(false);
  const [requestError, setRequestError] = useState<AxiosError | null>(null);
  const [cancelTokenSource, setCancelTokenSource] =
    useState<CancelTokenSource | null>(null);

  const cancelPreviousRequest = (cancelMessage: string) => {
    cancelTokenSource?.cancel(cancelMessage);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const makeRequest = async (apiMethodParams: any): Promise<void> => {
    setRequestData(null);
    setIsRequestInProgress(true);
    setIsRequestCanceled(false);
    setIsRequestSuccessful(false);
    setRequestError(null);
    setCancelTokenSource(null);

    try {
      const source = axios.CancelToken.source();
      setCancelTokenSource(source);
      const result = await apiMethod({
        ...apiMethodParams,
        cancelToken: source.token,
      } as P);
      setRequestData(result.data);
      setIsRequestCanceled(false);
      setIsRequestSuccessful(true);
    } catch (e) {
      if (axios.isCancel(e)) {
        setIsRequestCanceled(true);
      } else {
        setRequestError(e as AxiosError<unknown>);
      }
    } finally {
      setTimeout(() => {
        setIsRequestInProgress(false);
      }, delayAfterResolveInMS);
    }
  };

  return {
    requestData,
    isRequestInProgress,
    isRequestCanceled,
    isRequestSuccessful,
    requestError,
    makeRequest,
    cancelPreviousRequest,
  };
};

export default useCallApi;
