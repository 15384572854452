import { Graph } from '@antv/g6';
import { nanoid } from 'nanoid';
import { FunctionComponent } from 'react';

import {
  BaseNodeProperty,
  NodeParams,
  NodeProperty,
} from '@riga-claims/atlas-models';

import { DebouncedUpdateFlowGraphStore } from '@/hooks';

export enum FieldDataType {
  TEXT = 'TEXT',
  READONLY = 'READONLY',
  TEXTAREA = 'TEXTAREA',
  CHECKBOX = 'CHECKBOX',
  DROPDOWN = 'DROPDOWN',
  CUSTOM_PROPERTY = 'CUSTOM_PROPERTY',
  JSX_ELEMENT = 'JSX_ELEMENT',
}

interface BaseInputParams {
  fieldName: NodeProperty | BaseNodeProperty;
  value?: string;
  label?: string;
  required?: boolean;
  defaultValue?: string;
  title?: string;
}

export interface TextFieldParams extends BaseInputParams {
  value: string;
  placeholder?: string;
}
export type TextAreaFieldParams = TextFieldParams;
export type CheckboxFieldParams = BaseInputParams;
export type ReadOnlyFieldParams = BaseInputParams;
export interface DropdownFieldParams extends BaseInputParams {
  value: string;
  possibleValues: string[] | null;
}
export interface CustomPropertyParams {
  label: string;
  value: string;
}

export interface JSXElementRenderProps {
  debouncedUpdateFlowGraphStore: DebouncedUpdateFlowGraphStore;
  fieldData: JSXElementFieldData;
  graph: Graph;
  selectedNodeParams: NodeParams;
}

export interface JSXElementFieldParams {
  fieldName?: NodeProperty | BaseNodeProperty;
  renderElement: FunctionComponent<JSXElementRenderProps>;
  possibleValues?: string[] | null;
}

class BaseInputFieldData {
  public fieldName: NodeProperty | BaseNodeProperty;
  public key: string;
  public label?: string;
  public required?: boolean;
  public defaultValue?: string;
  public title?: string;

  constructor(params: BaseInputParams) {
    this.fieldName = params.fieldName;
    this.key = nanoid();
    this.label = params.label;
    this.required = params.required;
    this.defaultValue = params.defaultValue;
    this.title = params.title;
  }
}

export class ReadOnlyFieldData extends BaseInputFieldData {
  public type: FieldDataType;
  public value?: string;

  constructor(params: ReadOnlyFieldParams) {
    super(params);

    this.type = FieldDataType.READONLY;
    this.label = params.label;
    this.value = params.value;
  }
}

export class TextFieldData extends BaseInputFieldData {
  public type: FieldDataType;
  public placeholder?: string;

  constructor(params: TextFieldParams) {
    super(params);

    this.type = FieldDataType.TEXT;
    this.placeholder = params.placeholder;
  }
}

export class TextAreaFieldData extends BaseInputFieldData {
  public type: FieldDataType;
  public placeholder?: string;

  constructor(params: TextAreaFieldParams) {
    super(params);

    this.type = FieldDataType.TEXTAREA;
    this.placeholder = params.placeholder;
  }
}

export class CheckboxFieldData extends BaseInputFieldData {
  public type: FieldDataType;

  constructor(params: CheckboxFieldParams) {
    super(params);

    this.type = FieldDataType.CHECKBOX;
  }
}

export class DropdownFieldData extends BaseInputFieldData {
  public type: FieldDataType;
  public possibleValues: string[];

  constructor(params: DropdownFieldParams) {
    super(params);

    this.type = FieldDataType.DROPDOWN;
    this.possibleValues = params.possibleValues || [];
  }
}

export class CustomPropertyFieldData {
  public type: FieldDataType;
  public key: string;
  public value: string;
  public label: string;

  constructor(params: CustomPropertyParams) {
    this.type = FieldDataType.CUSTOM_PROPERTY;
    this.key = nanoid();
    this.value = params.value;
    this.label = params.label;
  }
}

export class JSXElementFieldData {
  public type: FieldDataType;
  public key: string;
  public renderElement: FunctionComponent<JSXElementRenderProps>;
  public possibleValues?: string[];

  constructor(params: JSXElementFieldParams) {
    this.key = nanoid();
    this.type = FieldDataType.JSX_ELEMENT;
    this.renderElement = params.renderElement;
    this.possibleValues = params.possibleValues || [];
  }
}

export type FieldData =
  | ReadOnlyFieldData
  | TextFieldData
  | TextAreaFieldData
  | CheckboxFieldData
  | DropdownFieldData
  | CustomPropertyFieldData;
