import {
  BaseNodeProperty,
  NodeProperty,
  Common_Motor_GetPoliciesNode,

} from '@riga-claims/atlas-models';
import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';
import BaseLayout from './BaseLayout';

class GetMotorPoliciesLayout extends BaseLayout {
  constructor(
    node: Common_Motor_GetPoliciesNode
  ) {
    super(node);

    const { properties } = node;
    const { variableName, answerCanContainPersonalData } = BaseNodeProperty;
    const {
      ssn,
      policyNumber,
      incidentDate,
      vehicleRegistrationNumber,
      country,
    } = NodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
          .addTextField(
            {
              fieldName: ssn,
              label: 'SSN',
              placeholder: 'SSN here',
              ...properties[ssn],
            },
            this.mappedFields
          )
          .addTextField(
            {
              fieldName: incidentDate,
              label: 'Incident date',
              placeholder: 'Incident date here',
              ...properties[
                incidentDate
              ],
            },
            this.mappedFields
          )
          .addTextField(
            {
              fieldName: policyNumber,
              label: 'Policy number',
              placeholder: 'Policy number here',
              ...properties[
                policyNumber
              ],
            },
            this.mappedFields
          )
          .addTextField(
            {
              fieldName: vehicleRegistrationNumber,
              label: 'Vehicle registration number',
              placeholder: 'Vehicle registration number here',
              ...properties[
                vehicleRegistrationNumber
              ],
            },
            this.mappedFields
          ).addDropdownField(
            {
              fieldName: country,
              label: 'Country',
              ...properties[
                country
              ],
            },
            this.mappedFields
          );

    this.contentTab
      .addGroup(nodePropertiesGroups.editNode)
      .addTextField(
        {
          fieldName: variableName,
          label: baseNodeProperties[variableName].label,
          placeholder: baseNodeProperties[variableName].placeholder,
          ...properties[variableName],
        },
        this.mappedFields
      )
      .addCheckboxField(
        {
          fieldName: answerCanContainPersonalData,
          label: baseNodeProperties[answerCanContainPersonalData].label,
          title: baseNodeProperties[answerCanContainPersonalData].title,
          ...properties[answerCanContainPersonalData],
        },
        this.mappedFields
      );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default GetMotorPoliciesLayout;
