import {
  BaseNodeProperty,
  NodeProperty,
  UpdateDraftMetadataNode,
} from '@riga-claims/atlas-models';
import {
  nodeProperties,
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';
import BaseLayout from './BaseLayout';

class UpdateDraftMetadataLayout extends BaseLayout {
  constructor(node: UpdateDraftMetadataNode) {
    super(node);

    const { properties } = node;
    const {
      variableName,
      answerCanContainPersonalData,
      questionContainsPersonalData,
    } = BaseNodeProperty;
    const { claimType, event, insuranceObject, companyNumber, policyNumber } = NodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.claimDetails)
      .addTextField(
        {
          fieldName: claimType,
          label: nodeProperties[claimType].label,
          ...properties[claimType],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: event,
          label: nodeProperties[event].label,
          ...properties[event],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: insuranceObject,
          label: nodeProperties[insuranceObject].label,
          ...properties[insuranceObject],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: companyNumber,
          label: nodeProperties[companyNumber].label,
          ...properties[companyNumber],
        },
        this.mappedFields
      ).addTextField(
        {
          fieldName: policyNumber,
          label: nodeProperties[policyNumber].label,
          ...properties[policyNumber],
        },
        this.mappedFields
      );

    this.contentTab
      .addGroup(nodePropertiesGroups.editNode)
      .addTextField(
        {
          fieldName: variableName,
          label: baseNodeProperties[variableName].label,
          placeholder: baseNodeProperties[variableName].placeholder,
          ...properties[variableName],
        },
        this.mappedFields
      )
      .addCheckboxField(
        {
          fieldName: questionContainsPersonalData,
          label: baseNodeProperties[questionContainsPersonalData].label,
          title: baseNodeProperties[questionContainsPersonalData].title,
          ...properties[questionContainsPersonalData],
        },
        this.mappedFields
      )
      .addCheckboxField(
        {
          fieldName: answerCanContainPersonalData,
          label: baseNodeProperties[answerCanContainPersonalData].label,
          ...properties[answerCanContainPersonalData],
        },
        this.mappedFields
      );

    this.addCommentField();
    this.addSummaryOverviewGroup();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default UpdateDraftMetadataLayout;
