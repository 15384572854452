import {
  BaseNodeProperty,
  Denmark_Motor_ShowVehiclesNode,
  Norway_Motor_ShowVehiclesNode,
  Finland_Motor_ShowVehiclesNode,
  NodeProperty,
} from '@riga-claims/atlas-models';
import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';
import BaseLayout from './BaseLayout';

class UserVehiclesLayout extends BaseLayout {
  constructor(
    node:
      | Denmark_Motor_ShowVehiclesNode
      | Norway_Motor_ShowVehiclesNode
      | Finland_Motor_ShowVehiclesNode
  ) {
    super(node);

    const { properties } = node;
    const { vehiclesData } = NodeProperty;
    const { variableName, answerCanContainPersonalData } = BaseNodeProperty;

    this.contentTab.addGroup('Data').addTextField(
      {
        fieldName: vehiclesData,
        label: 'Vehicles data',
        placeholder: 'Add vehicles data',
        ...properties[vehiclesData],
      },
      this.mappedFields
    );

    this.contentTab
      .addGroup(nodePropertiesGroups.editNode)
      .addTextField(
        {
          fieldName: variableName,
          label: baseNodeProperties[variableName].label,
          placeholder: baseNodeProperties[variableName].placeholder,
          ...properties[variableName],
        },
        this.mappedFields
      )
      .addCheckboxField(
        {
          fieldName: answerCanContainPersonalData,
          label: baseNodeProperties[answerCanContainPersonalData].label,
          title: baseNodeProperties[answerCanContainPersonalData].title,
          ...properties[answerCanContainPersonalData],
        },
        this.mappedFields
      );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.addAccessLogDataGroup({
      hasEnteredData: true,
    });
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default UserVehiclesLayout;
