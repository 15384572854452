import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import FullPageLoader from '@/components/Common/FullPageLoader/FullPageLoader';

let LDProvider:
  | null
  | (({ children }: { children: ReactNode }) => JSX.Element) = null;

interface WithLaunchDarklyProps {
  children: ReactNode;
  launchDarklyClientSideKey: string;
  launchDarklyUserKey: string;
  launchDarklyApplicationName: string;
  launchDarklyEnvironment: string;
}

const WithLaunchDarkly: FunctionComponent<WithLaunchDarklyProps> = ({
  children,
  launchDarklyClientSideKey,
  launchDarklyUserKey,
  launchDarklyApplicationName,
  launchDarklyEnvironment,
}: WithLaunchDarklyProps) => {
  const [isLoading, setIsLoading] = useState(true);

  const launchDarklyConfig = {
    clientSideID: launchDarklyClientSideKey,
    context: {
      key: launchDarklyUserKey,
      name: `${launchDarklyApplicationName} ${launchDarklyEnvironment}`,
    },
    reactOptions: { useCamelCaseFlagKeys: false },
  };

  useEffect(() => {
    const setLDProvider = async () => {
      LDProvider = await asyncWithLDProvider(launchDarklyConfig);
      setIsLoading(false);
    };

    void setLDProvider();
  }, []);

  // a state hook with "isLoading" is required to force a re-render after updating LDProvider variable
  // as the created LDProvider functional component can't be stored in the state hook (causes errors)
  return isLoading || !LDProvider ? (
    <FullPageLoader headerOffset />
  ) : (
    <LDProvider>{children}</LDProvider>
  );
};

export default WithLaunchDarkly;
