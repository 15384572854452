import {
  BaseNodeProperty,
  Norway_Travel_ShowPoliciesNode,
  Norway_Animal_ShowPoliciesNode,
  Norway_Property_ShowPoliciesNode,
  Finland_WorkersCompensation_ShowPoliciesNode,
  NodeProperty,
  MessageType,
  Denmark_Property_ShowPoliciesNode,
  Denmark_PersonalInjury_ShowUserPoliciesNode,
} from '@riga-claims/atlas-models';
import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';
import BaseLayout from './BaseLayout';

class ShowUserPoliciesLayout extends BaseLayout {
  constructor(
    node:
      | Norway_Travel_ShowPoliciesNode
      | Norway_Animal_ShowPoliciesNode
      | Finland_WorkersCompensation_ShowPoliciesNode
      | Denmark_Property_ShowPoliciesNode
      | Denmark_PersonalInjury_ShowUserPoliciesNode
      | Norway_Property_ShowPoliciesNode
  ) {
    super(node);

    const { properties } = node;
    const { message, variableName, answerCanContainPersonalData } =
      BaseNodeProperty;
    const { policyData, policies } = NodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties[message].label,
          placeholder: baseNodeProperties[message].placeholder,
          ...properties[message],
        },
        this.mappedFields
      );

    const dataGroup = this.contentTab.addGroup('Data');

    if (
      node.messageType === MessageType.Finland_WorkersCompensation_ShowPolicies
    ) {
      dataGroup.addTextField(
        {
          fieldName: policies,
          label: 'Policy data',
          placeholder: 'Add policy data',
          ...(node as Finland_WorkersCompensation_ShowPoliciesNode).properties[
            policies
          ],
        },
        this.mappedFields
      );
    } else {
      dataGroup.addTextField(
        {
          fieldName: policyData,
          label: 'Policy data',
          placeholder: 'Add policy data',
          ...(
            node as
              | Norway_Travel_ShowPoliciesNode
              | Norway_Animal_ShowPoliciesNode
              | Norway_Property_ShowPoliciesNode
              | Denmark_Property_ShowPoliciesNode
              | Denmark_PersonalInjury_ShowUserPoliciesNode
          ).properties[policyData],
        },
        this.mappedFields
      );
    }

    this.contentTab
      .addGroup(nodePropertiesGroups.editNode)
      .addTextField(
        {
          fieldName: variableName,
          label: baseNodeProperties[variableName].label,
          placeholder: baseNodeProperties[variableName].placeholder,
          ...properties[variableName],
        },
        this.mappedFields
      )
      .addCheckboxField(
        {
          fieldName: answerCanContainPersonalData,
          label: baseNodeProperties[answerCanContainPersonalData].label,
          title: baseNodeProperties[answerCanContainPersonalData].title,
          ...properties[answerCanContainPersonalData],
        },
        this.mappedFields
      );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.addAccessLogDataGroup({
      hasEnteredData: true,
    });
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default ShowUserPoliciesLayout;
