import {
  Country,
  BusinessArea,
  LineOfBusiness,
} from '@riga-claims/atlas-models';

export class Area {
  public country: Country;
  public businessArea: BusinessArea;
  public lineOfBusiness: LineOfBusiness;

  constructor(
    country: Country,
    businessArea: BusinessArea,
    lineOfBusiness: LineOfBusiness
  ) {
    this.country = country;
    this.businessArea = businessArea;
    this.lineOfBusiness = lineOfBusiness;
  }
}

export interface NodeFilter {
  include: Area[];
  exclude?: Area[];
}

export const commonArea: Area = new Area(
  Country.Common,
  BusinessArea.Common,
  LineOfBusiness.Common
);

export const balticArea: Area = new Area(
  Country.Baltics,
  BusinessArea.Common,
  LineOfBusiness.Common
);

export const testArea: Area = new Area(
  Country.Test,
  BusinessArea.Common,
  LineOfBusiness.Common
);

export const commonFilter: NodeFilter = {
  include: [commonArea],
};

export const balticFilter: NodeFilter = {
  include: [balticArea],
};

export const testFilter: NodeFilter = {
  include: [testArea],
};

export const invisibleFilter: NodeFilter = {
  include: [],
  exclude: [commonArea, balticArea, testArea],
};
