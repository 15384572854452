import {
  BaseNodeProperty,
  DefineSummaryNode,
  NodeProperty,
} from '@riga-claims/atlas-models';

import { LaunchSummaryEditorButton } from '@/components/SummaryEditor/SummaryEditor';
import {
  baseNodeProperties,
  nodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class DefineSummaryLayout extends BaseLayout {
  constructor(node: DefineSummaryNode) {
    super(node);

    const { properties } = node;
    const {
      designType,
      conditionalDesignType,
      title,
      language,
      summaryTemplateData,
    } = NodeProperty;
    const { variableName } = BaseNodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addDropdownField(
        {
          fieldName: designType,
          label: nodeProperties[designType].label,
          ...properties[designType],
        },
        this.mappedFields
      )
      .addJSXElementField(
        {
          renderElement: () =>
            LaunchSummaryEditorButton(properties[summaryTemplateData]),
          fieldName: summaryTemplateData,
          ...properties[summaryTemplateData],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: conditionalDesignType,
          label: nodeProperties[conditionalDesignType].label,
          placeholder: nodeProperties[conditionalDesignType].placeholder,
          ...properties[conditionalDesignType],
        },
        this.mappedFields
      );

    this.contentTab
      .addGroup(nodePropertiesGroups.accessibility)
      .addTextField(
        {
          fieldName: title,
          label: nodeProperties[title].label,
          placeholder: nodeProperties[title].placeholder,
          ...properties[title],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: language,
          label: nodeProperties[language].label,
          ...properties[language],
        },
        this.mappedFields
      );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.addCommentField();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default DefineSummaryLayout;
