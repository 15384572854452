import { customAlphabet } from 'nanoid';

export const UUID_LENGTH = 6;
const NANOID_ALPHABET =
  '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

const nanoid = customAlphabet(NANOID_ALPHABET, UUID_LENGTH);

const generateID = (): string => {
  return nanoid();
};

export default generateID;
