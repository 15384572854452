import { LineOfBusiness } from '@riga-claims/atlas-models';

const linesOfBusiness = {
  [LineOfBusiness.Common]: 'Generic',
  [LineOfBusiness.Travel]: 'Travel',
  [LineOfBusiness.Motor]: 'Motor',
  [LineOfBusiness.PersonalInjury]: 'Personal injury',
  [LineOfBusiness.PersonalInjuryEB]: 'Personal injury EB Health',
  [LineOfBusiness.Animal]: 'Animal',
  [LineOfBusiness.WorkersCompensation]: 'Workers compensation',
  [LineOfBusiness.ProfessionalAthletes]: 'Professional athletes',
  [LineOfBusiness.HealthNavigator]: 'Health navigator',
  [LineOfBusiness.Boat]: 'Boat',
  [LineOfBusiness.Property]: 'Property',
  [LineOfBusiness.FollowUp]: 'Follow-Up',
  [LineOfBusiness.Test]: 'Test',
  [LineOfBusiness.Viking]: 'Viking',
  [LineOfBusiness.BusinessClaims]: 'Business claims',
  [LineOfBusiness.Expat]: 'Expat',
  [LineOfBusiness.Practice]: 'Practice',
  [LineOfBusiness.Mobility]: 'Mobility',
  [LineOfBusiness.ProfessionalAthletesEn]: 'Professional athletes EN',
  [LineOfBusiness.ClaimsCenter]: 'Claims center',
  [LineOfBusiness.LegalAndLiability]: 'Legal and liability',
  [LineOfBusiness.CarGlass]: 'Car glass',
  [LineOfBusiness.Forest]: 'Forest',
  [LineOfBusiness.NordicCare]: 'Nordic care',
  [LineOfBusiness.TellUs]: 'TellUs',
};

export default linesOfBusiness;
