import { EditorConfiguration, EditorConfigurationKeys } from './types';

const config: EditorConfiguration = {
  [EditorConfigurationKeys.APP_INSIGHTS_INSTRUMENTATION_KEY]:
    '6188fd54-daea-4aa1-a60e-1e467d8eb82d',
  [EditorConfigurationKeys.LAUNCH_DARKLY_CLIENT_SIDE_KEY]:
    '5eaa94be375a130a428a6979',
  [EditorConfigurationKeys.LAUNCH_DARKLY_USER_KEY]:
    '7AC011B3-7B68-458E-B291-991875F5A319',
  [EditorConfigurationKeys.LAUNCH_DARKLY_APPLICATION_NAME]: 'Atlas Editor UI',
  [EditorConfigurationKeys.LAUNCH_DARKLY_ENVIRONMENT]: 'DEVELOPMENT',
};

export default config;
