import {
  BaseNodeProperty,
  Denmark_SilentAuthenticationNode,
  Finland_SilentAuthenticationNode,
  Norway_SilentAuthenticationNode,
  SilentAuthenticationNode,
  SilentEmployeeAuthenticationNode,
  Sweden_SilentAuthenticationNode,
} from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class SilentAuthenticationLayout extends BaseLayout {
  constructor(
    node:
      | Denmark_SilentAuthenticationNode
      | Finland_SilentAuthenticationNode
      | Norway_SilentAuthenticationNode
      | Sweden_SilentAuthenticationNode
      | SilentAuthenticationNode
      | SilentEmployeeAuthenticationNode
  ) {
    super(node);

    const { properties } = node;
    const { variableName } = BaseNodeProperty;

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default SilentAuthenticationLayout;
