import {
  BaseNodeProperty,
  ListFromCarouselNode,
  NodeProperty,
} from '@riga-claims/atlas-models';
import {
  baseNodeProperties,
  nodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';
import ExternalLink from '@/components/BoardPage/NodeProperties/NodePropertiesElements/ExternalLink/ExternalLink';
import { AppRoutes } from '@/routes';

class ItemListLayout extends BaseLayout {
  constructor(node: ListFromCarouselNode) {
    super(node);

    const { properties } = node;
    const {
      carouselNodeName,
      column1,
      column2,
      column3,
      noItemsInListMessage,
      addNewButtonText,
      removeButtonText,
      undoRemoveButtonText,
      minItemCount,
      maxItemCount,
      minCountErrorMessage,
      maxCountErrorMessage,
      sumVariableName,
      addItemButtons,
      continueButtons,
      showTotalSum,
      totalSumLabel,
      totalSumUnits,
      tableDefinition,
    } = NodeProperty;
    const { message, variableName } = BaseNodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties[message].label,
          placeholder: baseNodeProperties[message].placeholder,
          ...properties[message],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: carouselNodeName,
          label: nodeProperties[carouselNodeName].label,
          placeholder: nodeProperties[carouselNodeName].placeholder,
          ...properties[carouselNodeName],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: addNewButtonText,
          label: nodeProperties[addNewButtonText].label,
          placeholder: nodeProperties[addNewButtonText].placeholder,
          ...properties[addNewButtonText],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: removeButtonText,
          label: nodeProperties[removeButtonText].label,
          placeholder: nodeProperties[removeButtonText].placeholder,
          ...properties[removeButtonText],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: undoRemoveButtonText,
          label: nodeProperties[undoRemoveButtonText].label,
          placeholder: nodeProperties[undoRemoveButtonText].placeholder,
          ...properties[undoRemoveButtonText],
        },
        this.mappedFields
      );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.validationTab
      .addGroup(nodePropertiesGroups.listLength)
      .addTextField(
        {
          fieldName: minItemCount,
          label: nodeProperties[minItemCount].label,
          placeholder: nodeProperties[minItemCount].placeholder,
          ...properties[minItemCount],
        },
        this.mappedFields
      )
      .addTextAreaField(
        {
          fieldName: minCountErrorMessage,
          label: nodeProperties[minCountErrorMessage].label,
          placeholder: nodeProperties[minCountErrorMessage].placeholder,
          ...properties[minCountErrorMessage],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: maxItemCount,
          label: nodeProperties[maxItemCount].label,
          placeholder: nodeProperties[maxItemCount].placeholder,
          ...properties[maxItemCount],
        },
        this.mappedFields
      )
      .addTextAreaField(
        {
          fieldName: maxCountErrorMessage,
          label: nodeProperties[maxCountErrorMessage].label,
          placeholder: nodeProperties[maxCountErrorMessage].placeholder,
          ...properties[maxCountErrorMessage],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: addItemButtons,
          label: nodeProperties[addItemButtons].label,
          placeholder: nodeProperties[addItemButtons].placeholder,
          ...properties[addItemButtons],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: continueButtons,
          label: nodeProperties[continueButtons].label,
          placeholder: nodeProperties[continueButtons].placeholder,
          ...properties[continueButtons],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: noItemsInListMessage,
          label: nodeProperties[noItemsInListMessage].label,
          placeholder: nodeProperties[noItemsInListMessage].placeholder,
          ...properties[noItemsInListMessage],
        },
        this.mappedFields
      );

    this.propertiesTab
      .addGroup(nodePropertiesGroups.items)
      .addTextField(
        {
          fieldName: column1,
          label: nodeProperties[column1].label,
          placeholder: nodeProperties[column1].placeholder,
          ...properties[column1],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: column2,
          label: nodeProperties[column2].label,
          placeholder: nodeProperties[column2].placeholder,
          ...properties[column2],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: column3,
          label: nodeProperties[column3].label,
          placeholder: nodeProperties[column3].placeholder,
          ...properties[column3],
        },
        this.mappedFields
      );

    this.propertiesTab
      .addGroup('Items (TellUs)')
      .addTextAreaField(
        {
          fieldName: tableDefinition,
          label: 'Table definition',
          ...properties[tableDefinition],
        },
        this.mappedFields
      )
      .addJSXElementField(
        {
          renderElement: () =>
            ExternalLink({
              buttonText: 'Data source composer',
              url: `${AppRoutes.TOOLS}/itemList.html`,
            }),
          fieldName: tableDefinition,
          ...properties[tableDefinition],
        },
        this.mappedFields
      );

    this.propertiesTab
      .addGroup(nodePropertiesGroups.totalSum)
      .addTextField(
        {
          fieldName: sumVariableName,
          label: nodeProperties[sumVariableName].label,
          placeholder: nodeProperties[sumVariableName].placeholder,
          ...properties[sumVariableName],
        },
        this.mappedFields
      )
      .addCheckboxField(
        {
          fieldName: showTotalSum,
          label: nodeProperties[showTotalSum].label,
          ...properties[showTotalSum],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: totalSumLabel,
          label: nodeProperties[totalSumLabel].label,
          placeholder: nodeProperties[totalSumLabel].placeholder,
          ...properties[totalSumLabel],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: totalSumUnits,
          label: nodeProperties[totalSumUnits].label,
          placeholder: nodeProperties[totalSumUnits].placeholder,
          ...properties[totalSumUnits],
        },
        this.mappedFields
      );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.addAccessLogDataGroup({
      hasEnteredData: true,
    });
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}
export default ItemListLayout;
