import {  
    NodeProperty, 
    Finland_SelectPolarisClassifierNode } from '@riga-claims/atlas-models';
  
  import {
    nodePropertiesGroups,
    nodeProperties
  } from '../nodeProperties/labels';
  
  import ClassifierLayout from './ClassifierLayout';
  
  class SelectPolarisClassifierLayout extends ClassifierLayout {
    constructor(node: Finland_SelectPolarisClassifierNode
    ) {
      super(node);
  
      const { properties } = node;
      const { classifierType } = NodeProperty;
  
      const customerFacingGroup = this.contentTab.getGroups().find((group) => group.label === nodePropertiesGroups.customerFacing)
      if(customerFacingGroup){
        customerFacingGroup.addDropdownFieldAtTop(
            {
                fieldName: classifierType,
                label: nodeProperties[classifierType].label,
                ...properties[classifierType],
            },
            this.mappedFields
        );
      }
        
    }
  }
  
  export default SelectPolarisClassifierLayout;
  