import {
  BaseNodeProperty,
  GetSubmissionRelationNode,
  NodeProperty,
} from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class GetSubmissionRelationLayout extends BaseLayout {
  constructor(node: GetSubmissionRelationNode) {
    super(node);

    const { properties } = node;
    const { submissionId } = NodeProperty;
    const { variableName } = BaseNodeProperty;

    this.contentTab.addGroup(nodePropertiesGroups.customerFacing).addTextField(
      {
        fieldName: submissionId,
        label: 'Submission Id',
        placeholder: 'Submission Id',
        ...properties[submissionId],
      },
      this.mappedFields
    );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.addCommentField();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default GetSubmissionRelationLayout;
