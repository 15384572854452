export enum NodeLabels {
  Undefined = 'Undefined',
  Message = 'Message',
  InputText = 'Input text',
  InputTextArea = 'Input text area',
  InputChoice = 'Input choice',
  ShowPolicies = 'Show policies',
  InputFromCarouselList = 'Input from carousel list',
  InputCity = 'Input city',
  InputMultipleChoice = 'Input multiple choice',
  InputDate = 'Input Date',
  InputCurrency = 'Input currency',
  InputTime = 'Input Time',
  InputCountry = 'Input country',
  InputPostalCode = 'Input postal code',
  InputBankAccount = 'Input bank account',
  InputClassifier = 'Input classifier',
  InputChoiceFromCarousel = 'Input choice from carousel',
  InputSsn = 'Input SSN',
  ListFromCarousel = 'List from carousel',
  FileUpload = 'File upload',
  FileUploadAttachmentViewer = 'File upload attachment viewer',
  RatingStars = 'Rating stars',
  Authentication = 'Authentication',
  SilentAuthentication = 'Silent authentication',
  SilentEmployeeAuthentication = 'Silent employee authentication',
  MyPagesAuthentication = 'MyPages authentication',
  Container = 'Container',
  Section = 'Section',
  Carousel = 'Carousel',
  Start = 'Start',
  End = 'End',
  Splitter = 'Splitter',
  SetValue = 'Set value',
  SetStatistics = 'Set statistics',
  DecryptValue = 'Decrypt value',
  Summary = 'Summary',
  DefineSummary = 'Define summary',
  CommonSendData = 'Common send data to Fronten',
  CommonSendCompass = 'Common send data to Compass',
  SendData = 'Send data',
  IntoSendData = 'Into Send Data',
  CarGlassSendData = 'CarGlass Send Data',
  CommercialSendData = 'Commercial send data',
  GetUserInformation = 'Get user information',
  GetUserPolicies = 'Get user policies',
  CommonGetMotorPolicies = 'Get motor policies',
  GetCommercialPolicies = 'Get commercial policies',
  UserVehicles = 'User vehicles',
  GetUserVehicles = 'Get user vehicles',
  InsuredPeople = 'Insured people',
  GetInsuredPeople = 'Get insured people',
  ItemList = 'Item list',
  VerifyCoverage = 'Verify coverage',
  BodyPartCode = 'Body part Code',
  DiagnosisCode = 'Diagnosis code',
  SportCode = 'Sport code',
  MedicineCode = 'Medicine code',
  TradeUnionCode = 'Trade union code',
  ClaimLiabilitySearch = 'Claim liability search',
  CatBreeds = 'Cat breeds',
  DogBreeds = 'Dog breeds',
  AnimalDiagnosis = 'Animal diagnosis',
  ShowExposures = 'Show exposures',
  GetExposures = 'Get exposures',
  ShowOccupationalClasses = 'Show occupational classes',
  ShowDepartments = 'Show departments',
  ShowProducts = 'Show products',
  GetOccupationalClasses = 'Get occupational classes',
  EmployeeAuthentication = 'Employee Authentication',
  LongList = 'Long List',
  WeakAuthentication = 'Weak Authentication',
  InputPhone = 'Input phone',
  GetAllClaims = 'Get all claims',
  GetAllNOTravelClaims = 'Get all Norway travel claims',
  ShowAllClaims = 'Show all claims',
  FraudScore = 'Fraud Score',
  Nordic_Care_RecommendedServices = 'Recommended services',
  Overview = 'Overview',
  SelectCountry = 'Select country',
  GetSubmissionRelation = 'Get submission relation',
  LoadDraft = 'Load Draft',
  SaveDraft = 'Save Draft',
  UpdateDraftMetadata = 'Update Draft Metadata',
  SelectPolarisClassifier = 'Select Polaris Classifier',
  StickyNote = 'Sticky note',
  GetPrefillStorage = 'Get from Prefill storage',
  SetPrefillStorage = 'Set in Prefill storage',
  CommonMotorSendData = 'Common Motor Send Data',
  GetVrfPolicies = 'Get VRF policies',
  GetCustomerInformationVrf = 'Get customer information VRF',
  DuplicateVrfClaimCheck = 'Duplicate VRF claim check',
  GetUserWorkshopRoles = 'Get user workshop roles',
}

export enum BalticNodeLabels {
  InputDateTime = 'Input date time',
  InputAttachment = 'Input attachment',
  SendData = 'Send data',
  Map = 'Map',
  Summary = 'Summary',
  CheckAuthentication = 'Check authentication',
  Authentication = 'Authentication',
  GetCustomerInformation = 'Get customer information',
  BankAccount = 'BankAccount',
  GetUserPolicies = 'Get user policies',
  GetUserInsuredPersons = 'Get userInsured persons',
  UserInsuredPersons = 'User insured persons',
  CustomDropdown = 'Custom dropdown',
  GetInsuredPersonsList = 'Get insured persons list',
  TotalAmount = 'Total amount',
  GetUserVehicles = 'GetUser vehicles',
  UserVehicles = 'User vehicles',
  AccidentAct = 'Accident act',
  DriverDetails = 'Driver details',
  SecondVehicle = 'Second vehicle',
  PoliceStation = 'Police station',
  Steering = 'Steering',
  GetUserObjects = 'GetUser objects',
  UserObjects = 'User objects',
  DamagedObject = 'Damaged object',
  GetUserPets = 'Get user pets',
  UserPets = 'User pets',
  CreditCardType = 'Credit card type',
  SufferedPersons = 'Suffered persons',
  TravelInputCountry = 'Travel input country',
  HealthInputAttachment = 'Health input attachment',
}
