import {
  NodeProperty,
  BaseNodeProperty,
  Finland_WorkersCompensation_ShowProductsNode,
} from '@riga-claims/atlas-models';
import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';
import BaseLayout from './BaseLayout';

class FinlandWorkersCompShowProductsLayout extends BaseLayout {
  constructor(node: Finland_WorkersCompensation_ShowProductsNode) {
    super(node);

    const { properties } = node;
    const { variableName, message } = BaseNodeProperty;
    const { exposure } = NodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties[message].label,
          placeholder: baseNodeProperties[message].placeholder,
          ...properties[message],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: exposure,
          label: 'Exposure',
          placeholder: 'Exposure object from selected exposure',
          ...node.properties[exposure],
        },
        this.mappedFields
      );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.addAccessLogDataGroup();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default FinlandWorkersCompShowProductsLayout;
