import {
  BaseNodeProperty,
  GetAllClaimsNode,
  NodeProperty,
  Norway_Travel_GetAllClaimsNode,
} from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class GetAllClaimsLayout extends BaseLayout {
  constructor(node: GetAllClaimsNode | Norway_Travel_GetAllClaimsNode) {
    super(node as GetAllClaimsNode);

    const { properties } = node as GetAllClaimsNode;
    const { variableName } = BaseNodeProperty;
    const { country, claimNumber } = NodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextField(
        {
          fieldName: country,
          label: 'Country',
          placeholder: 'Norway, Finland, Sweden, Denmark',
          ...properties[country],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: claimNumber,
          label: 'Claim number',
          placeholder: 'Claim number',
          ...properties[claimNumber],
        },
        this.mappedFields
      );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default GetAllClaimsLayout;
