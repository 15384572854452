import {
  BaseNodeProperty,
  NodeProperty,
  SectionNode,
} from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class SectionLayout extends BaseLayout {
  constructor(node: SectionNode) {
    super(node);

    const { properties } = node;
    const { message, variableName } = BaseNodeProperty;
    const { continueButtonText, hideNavigation, isDeadEnd } = NodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.settings)
      .addCheckboxField(
        {
          fieldName: hideNavigation,
          label: nodeProperties.hideNavigation.label,
          ...properties[hideNavigation],
        },
        this.mappedFields
      )
      .addCheckboxField(
        {
          fieldName: isDeadEnd,
          label: nodeProperties.isDeadEnd.label,
          ...properties[isDeadEnd],
        },
        this.mappedFields
      );

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties[message].label,
          placeholder: baseNodeProperties[message].placeholder,
          ...properties[message],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: continueButtonText,
          label: nodeProperties.continueButtonText.label,
          placeholder: nodeProperties.continueButtonText.placeholder,
          ...properties[continueButtonText],
        },
        this.mappedFields
      );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextAreaField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.addCommentField();
    this.addAccessLogDataGroup({
      hasEnteredData: true,
    });

    this.addSummaryOverviewGroup();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default SectionLayout;
