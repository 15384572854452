import {
  NodeProperty,
  BaseNodeProperty,
  InputMultipleChoiceNode,
} from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';
import MultipleChoiceItemGroup from '@/components/MultipleChoiceItemGroup/MultipleChoiceItemGroup';

class InputMultipleChoiceLayout extends BaseLayout {
  constructor(node: InputMultipleChoiceNode) {
    super(node);

    const { properties } = node;
    const { variableName, message } = BaseNodeProperty;

    const { item, defaultButton, splitChar } = NodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties[message].label,
          placeholder: baseNodeProperties[message].placeholder,
          ...properties[message],
        },
        this.mappedFields
      );

    this.contentTab
      .addGroup(nodePropertiesGroups.choices)
      .addJSXElementField(
        {
          fieldName: item,
          renderElement: (props) => <MultipleChoiceItemGroup {...props} />,
          ...properties[item],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: splitChar,
          label: nodeProperties[splitChar].label,
          placeholder: nodeProperties[splitChar].placeholder,
          ...properties[splitChar],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: defaultButton,
          label: nodeProperties[defaultButton].label,
          placeholder: nodeProperties[defaultButton].placeholder,
          ...properties[defaultButton],
        },
        this.mappedFields
      );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.addAccessLogDataGroup({
      hasEnteredData: true,
    });
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default InputMultipleChoiceLayout;
