import {
  BaseNode,
  Ba_AccidentActNode,
  Ba_AuthenticationNode,
  Ba_BankAccountNode,
  Ba_CheckAuthenticationNode,
  Ba_CreditCardTypeNode,
  Ba_CustomDropdownNode,
  Ba_DamagedObjectNode,
  Ba_DriverDetailsNode,
  Ba_GetCustomerInformationNode,
  Ba_GetInsuredPersonsListNode,
  Ba_GetUserInsuredPersonsNode,
  Ba_GetUserObjectsNode,
  Ba_GetUserPetsNode,
  Ba_GetUserPoliciesNode,
  Ba_GetUserVehiclesNode,
  Ba_HealthInputAttachmentNode,
  Ba_InputAttachmentNode,
  Ba_MapNode,
  Ba_PoliceStationNode,
  Ba_SecondVehicleNode,
  Ba_SendDataNode,
  Ba_SteeringNode,
  Ba_SufferedPersonsNode,
  Ba_SummaryNode,
  Ba_TotalAmountNode,
  Ba_TravelInputCountryNode,
  Ba_UserInsuredPersonsNode,
  Ba_UserObjectsNode,
  Ba_UserPetsNode,
  Ba_UserVehiclesNode,
  BusinessArea,
  CarouselNode,
  Common_SendDataCompassNode,
  Common_SendDataNode,
  ContainerNode,
  SectionNode,
  Country,
  DefineSummaryNode,
  Denmark_AuthenticationNode,
  Denmark_GetUserInformationNode,
  Denmark_Motor_GetVehiclesNode,
  Denmark_Motor_SendDataNode,
  Denmark_Motor_ShowVehiclesNode,
  Denmark_PersonalInjury_SendDataNode,
  Denmark_PersonalInjury_GetUserPoliciesNode,
  Denmark_PersonalInjury_ShowUserPoliciesNode,
  Denmark_Property_GetPoliciesNode,
  Denmark_Property_SendDataNode,
  Denmark_Property_ShowPoliciesNode,
  Denmark_SilentAuthenticationNode,
  Denmark_SummaryNode,
  EndOfConversationNode,
  FileUploadNode,
  FileUpload_AttachmentViewerNode,
  Finland_Animal_AnimalDiagnosisNode,
  Finland_Animal_CatBreedsNode,
  Finland_Animal_DogBreedsNode,
  Finland_Animal_SendDataNode,
  Finland_Animal_GetPoliciesNode,
  Finland_Animal_ShowPoliciesNode,
  Finland_Athletes_SendDataNode,
  Finland_AuthenticationNode,
  Finland_HealthNavigator_ClaimLiabilitySearchNode,
  Finland_HealthNavigator_SendDataNode,
  Finland_InputBankAccountNode,
  Finland_Motor_GetPoliciesNode,
  Finland_InputCityNode,
  Finland_InputPostalCodeNode,
  Finland_Motor_SendDataNode,
  Finland_Motor_ShowVehiclesNode,
  Finland_MTPL_SendDataNode,
  Finland_Into_SendDataNode,
  Finland_MyPagesAuthenticationNode,
  Finland_PersonalInjury_BodyPartCodeNode,
  Finland_PersonalInjury_DiagnosisCodeNode,
  Finland_PersonalInjury_GetInsuredPeopleNode,
  Finland_PersonalInjury_MedicineCodeNode,
  Finland_PersonalInjury_SendDataNode,
  Finland_PersonalInjury_ShowInsuredPeopleNode,
  Finland_PersonalInjury_SportCodeNode,
  Finland_PersonalInjury_TradeUnionCodeNode,
  Finland_Property_SendDataNode,
  Finland_SilentAuthenticationNode,
  Finland_SummaryNode,
  Finland_Travel_GetUserInformationNode,
  Finland_InputCountryNode,
  Finland_Travel_SendDataNode,
  Finland_WorkersCompensation_GetPoliciesNode,
  Finland_WorkersCompensation_GetUserInformationNode,
  Finland_WorkersCompensation_SendDataNode,
  Finland_WorkersCompensation_ShowExposuresNode,
  Finland_WorkersCompensation_ShowPoliciesNode,
  Finland_WorkersCompensation_ShowOccupationalClassesNode,
  Finland_WorkersCompensation_GetOccupationalClassesNode,
  InputChoiceFromCarouselNode,
  InputChoiceNode,
  InputClassifierNode,
  InputCurrencyNode,
  InputDateNode,
  InputDateTimeNode,
  InputMultipleChoiceNode,
  InputTextNode,
  InputTextAreaNode,
  InputTimeNode,
  LineOfBusiness,
  ListFromCarouselNode,
  MessageNode,
  MessageType,
  Norway_Animal_GetPoliciesNode,
  Norway_Animal_SendDataNode,
  Norway_Animal_ShowPoliciesNode,
  Norway_AuthenticationNode,
  Norway_CarGlass_SendDataNode,
  Norway_Expatriates_CountryNode,
  Norway_Expatriates_SendDataNode,
  Norway_GetUserInformationNode,
  Norway_Motor_GetVehiclesNode,
  Norway_Motor_SendDataNode,
  Norway_Motor_GetPoliciesNode,
  Norway_Motor_ShowVehiclesNode,
  Norway_Motor_Commercial_SendDataNode,
  Norway_PersonalInjury_Commercial_GetPoliciesNode,
  Norway_PersonalInjury_Commercial_SendDataNode,
  Norway_SilentAuthenticationNode,
  Norway_SummaryNode,
  Norway_Travel_CountryNode,
  Norway_Travel_GetPoliciesNode,
  Norway_Travel_SendDataNode,
  Norway_Travel_ShowPoliciesNode,
  Norway_Property_Private_SendDataNode,
  Norway_Property_GetPoliciesNode,
  Norway_Property_ShowPoliciesNode,
  RatingStarsNode,
  SetStatisticsNode,
  SetValueNode,
  SplitterNode,
  StartNode,
  SummaryNode,
  Sweden_AuthenticationNode,
  Sweden_FollowUp_DecryptNode,
  Sweden_FollowUp_SendDataNode,
  Sweden_InputCountryNode,
  Sweden_Motor_SendDataNode,
  Sweden_PersonalInjury_SendDataNode,
  Sweden_Property_GetPoliciesNode,
  Sweden_Property_SendDataNode,
  Sweden_SilentAuthenticationNode,
  Sweden_SummaryNode,
  Common_Motor_GetPoliciesNode,
  Test_CountryNode,
  InputSsnNode,
  EmployeeAuthenticationNode,
  LongListNode,
  WeakAuthenticationNode,
  SilentAuthenticationNode,
  SilentEmployeeAuthenticationNode,
  NodeParams,
  InputPhoneNode,
  Denmark_Commercial_Travel_SendDataNode,
  Finland_Boat_SendDataNode,
  GetAllClaimsNode,
  Norway_Travel_ShowAllClaimsNode,
  FraudScoreNode,
  Norway_Travel_GetAllClaimsNode,
  Nordic_Care_RecommendedServicesNode,
  OverviewNode,
  SelectCountryNode,
  GetSubmissionRelationNode,
  LoadDraftNode,
  SaveDraftNode,
  Finland_SelectPolarisClassifierNode,
  Finland_WorkersCompensation_ShowDepartmentsNode,
  Finland_Forest_SendDataNode,
  Finland_WorkersCompensation_ShowProductsNode,
  Finland_WorkersCompensation_GetExposuresNode,
  SetPrefillStorageNode,
  GetPrefillStorageNode,
  Norway_Expatriate_GetPoliciesNode,
  Common_Motor_SendDataNode,
  Sweden_Motor_GetVrfPoliciesNode,
  UpdateDraftMetadataNode,
  Sweden_Motor_GetCustomerInformationVrfNode,
  Sweden_Motor_DuplicateVrfClaimCheckNode,
  Sweden_Motor_GetUserWorkshopRolesNode,
} from '@riga-claims/atlas-models';

import { nodeDesigns, NodeDesign } from './nodeDesigns/nodeDesigns';
import { NodeLabels, BalticNodeLabels } from './nodeLabels/nodeLabels';
import {
  Area,
  NodeFilter,
  balticFilter,
  commonFilter,
  testFilter,
  commonArea,
  invisibleFilter,
} from './nodeFilters/nodeFilters';
import {
  BaseLayout,
  DefaultLayout,
  MessageLayout,
  InputTextLayout,
  InputTextAreaLayout,
  InputChoiceLayout,
  InputMultipleChoiceLayout,
  InputDateLayout,
  InputTimeLayout,
  InputCurrencyLayout,
  SetValueLayout,
  SplitterLayout,
  ContainerLayout,
  SectionLayout,
  SaveDraftLayout,
  LoadDraftLayout,
  CarouselLayout,
  RatingStarsLayout,
  DefineSummaryLayout,
  SummaryLayout,
  StartLayout,
  EndLayout,
  SilentAuthenticationLayout,
  AuthenticationLayout,
  DecryptLayout,
  CommonSendDataLayout,
  FileUploadLayout,
  FileUploadAttachmentViewerLayout,
  ItemListLayout,
  UserVehiclesLayout,
  DenmarkGetUserVehiclesLayout,
  NorwayGetUserVehiclesLayout,
  GetUserPoliciesLayout,
  InputAutocompleteLayout,
  InsuredPeopleLayout,
  InputBankAccountLayout,
  MyPagesAuthenticationLayout,
  GetUserInformationLayout,
  VerifyCoverageLayout,
  GetInsuredPeopleLayout,
  ShowUserPoliciesLayout,
  InputChoiceFromCarouselLayout,
  WeakAuthenticationLayout,
  InputSsnLayout,
  SetStatisticsLayout,
  SendDataLayout,
  LongListLayout,
  FinlandAnimalShowPoliciesLayout,
  FinlandAnimalGetPoliciesLayout,
  DenmarkPersonalInjuryGetPoliciesLayout,
  InputPhoneLayout,
  GetAllClaimsLayout,
  ShowAllClaimsLayout,
  FraudScoreLayout,
  RecommendedServicesLayout,
  OverviewLayout,
  ClassifierLayout,
  SelectPolarisClassifierLayout,
  FinlandInputCityLayout,
  UpdateDraftMetadataLayout,
  GetUserWorkshopRolesLayout,
  GetMotorPoliciesLayout,
} from './nodeLayouts';
import GetSubmissionRelationLayout from './nodeLayouts/GetSubmissionRelationLayout';
import FinlandWorkersCompShowOccupationalClassesLayout from './nodeLayouts/FinlandWorkersCompShowOccupationalClassesLayout';
import FinlandWorkersCompShowExposuresLayout from './nodeLayouts/FinlandWorkersCompShowExposuresLayout';
import FinlandWorkersCompGetOccupationalClassesLayout from './nodeLayouts/FinlandWorkersCompGetOccupationalClassesLayout';
import FinlandWorkersCompShowProductsLayout from './nodeLayouts/FinlandWorkersCompShowProductsLayout';
import FinlandWorkersCompShowDepartmentsLayout from './nodeLayouts/FinlandWorkersCompShowDepartmentsLayout';
import FinlandWorkersCompGetExposuresLayout from './nodeLayouts/FinlandWorkersCompGetExposuresLayout';
import SetPrefillStorageLayout from './nodeLayouts/SetPrefillStorageLayout';
import GetPrefillStorageLayout from './nodeLayouts/GetPrefillStorageLayout';

export interface NodeVisualConfiguration {
  messageType: MessageType;
  label: NodeLabels | BalticNodeLabels | string;
  design: (nodeParams?: NodeParams) => NodeDesign;
  filter: NodeFilter;
  createLayout: (nodeParams: NodeParams) => BaseLayout;
  getNode: (nodeParams: NodeParams) => BaseNode;
}

export const getUndefinedNodeVisualConfiguration = (
  messageType: MessageType
): NodeVisualConfiguration => ({
  messageType,
  label: MessageType[messageType],
  design: () => nodeDesigns.Default,
  filter: commonFilter,
  createLayout: (nodeParams: NodeParams) =>
    new DefaultLayout(new BaseNode(nodeParams)),
  getNode: (nodeParams: NodeParams) => new BaseNode(nodeParams),
});

const nodeVisualConfigurations: Record<MessageType, NodeVisualConfiguration> = {
  [MessageType._undefined]: {
    messageType: MessageType._undefined,
    label: NodeLabels.Undefined,
    design: () => nodeDesigns.Default,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new BaseNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new BaseNode(nodeParams),
  },
  [MessageType.Message]: {
    messageType: MessageType.Message,
    label: NodeLabels.Message,
    design: () => nodeDesigns.Message,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new MessageLayout(new MessageNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new MessageNode(nodeParams),
  },
  [MessageType.InputText]: {
    messageType: MessageType.InputText,
    label: NodeLabels.InputText,
    design: () => nodeDesigns.InputText,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new InputTextLayout(new InputTextNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new InputTextNode(nodeParams),
  },
  [MessageType.InputTextArea]: {
    messageType: MessageType.InputTextArea,
    label: NodeLabels.InputTextArea,
    design: () => nodeDesigns.InputTextArea,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new InputTextAreaLayout(new InputTextAreaNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new InputTextAreaNode(nodeParams),
  },
  [MessageType.InputChoice]: {
    messageType: MessageType.InputChoice,
    label: NodeLabels.InputChoice,
    design: () => nodeDesigns.InputChoice,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new InputChoiceLayout(new InputChoiceNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new InputChoiceNode(nodeParams),
  },
  [MessageType.InputDate]: {
    messageType: MessageType.InputDate,
    label: NodeLabels.InputDate,
    design: () => nodeDesigns.InputDate,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new InputDateLayout(new InputDateNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new InputDateNode(nodeParams),
  },
  [MessageType.InputTime]: {
    messageType: MessageType.InputTime,
    label: NodeLabels.InputTime,
    design: () => nodeDesigns.InputTime,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new InputTimeLayout(new InputTimeNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new InputTimeNode(nodeParams),
  },
  [MessageType.InputDateTime]: {
    messageType: MessageType.InputDateTime,
    label: BalticNodeLabels.InputDateTime,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new InputDateTimeNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new InputDateTimeNode(nodeParams),
  },
  [MessageType.FileUpload_AttachmentViewer]: {
    messageType: MessageType.FileUpload_AttachmentViewer,
    label: NodeLabels.FileUploadAttachmentViewer,
    design: () => nodeDesigns.FileUpload,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new FileUploadAttachmentViewerLayout(
        new FileUpload_AttachmentViewerNode(nodeParams)
      ),
    getNode: (nodeParams) => new FileUpload_AttachmentViewerNode(nodeParams),
  },
  [MessageType.FileUpload]: {
    messageType: MessageType.FileUpload,
    label: NodeLabels.FileUpload,
    design: () => nodeDesigns.FileUpload,
    filter: {
      include: [
        new Area(Country.Finland, BusinessArea.Common, LineOfBusiness.Common),
        new Area(Country.Sweden, BusinessArea.Common, LineOfBusiness.Motor),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new FileUploadLayout(new FileUploadNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new FileUploadNode(nodeParams),
  },
  [MessageType.SetValue]: {
    messageType: MessageType.SetValue,
    label: NodeLabels.SetValue,
    design: () => nodeDesigns.SetValue,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new SetValueLayout(new SetValueNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new SetValueNode(nodeParams),
  },
  [MessageType.Container]: {
    messageType: MessageType.Container,
    label: NodeLabels.Container,
    design: () => nodeDesigns.Container,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new ContainerLayout(new ContainerNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new ContainerNode(nodeParams),
  },
  [MessageType.Section]: {
    messageType: MessageType.Section,
    label: NodeLabels.Section,
    design: (nodeParams?: NodeParams) =>
      nodeParams?.properties?.isDeadEnd?.value === 'true'
        ? nodeDesigns.DeadEnd
        : nodeDesigns.Section,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new SectionLayout(new SectionNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new SectionNode(nodeParams),
  },
  [MessageType.Carousel]: {
    messageType: MessageType.Carousel,
    label: NodeLabels.Carousel,
    design: () => nodeDesigns.Carousel,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new CarouselLayout(new CarouselNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new CarouselNode(nodeParams),
  },
  [MessageType.EndOfConversation]: {
    messageType: MessageType.EndOfConversation,
    label: NodeLabels.End,
    design: () => nodeDesigns.End,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new EndLayout(new EndOfConversationNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new EndOfConversationNode(nodeParams),
  },
  [MessageType.InputMultipleChoice]: {
    messageType: MessageType.InputMultipleChoice,
    label: NodeLabels.InputMultipleChoice,
    design: () => nodeDesigns.InputMultipleChoice,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new InputMultipleChoiceLayout(new InputMultipleChoiceNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new InputMultipleChoiceNode(nodeParams),
  },
  [MessageType.RatingStars]: {
    messageType: MessageType.RatingStars,
    label: NodeLabels.RatingStars,
    design: () => nodeDesigns.RatingStars,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new RatingStarsLayout(new RatingStarsNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new RatingStarsNode(nodeParams),
  },
  [MessageType.SetStatistics]: {
    messageType: MessageType.SetStatistics,
    label: NodeLabels.SetStatistics,
    design: () => nodeDesigns.SetStatistics,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new SetStatisticsLayout(new SetStatisticsNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new SetStatisticsNode(nodeParams),
  },
  [MessageType.InputClassifier]: {
    messageType: MessageType.InputClassifier,
    label: NodeLabels.InputClassifier,
    design: () => nodeDesigns.Default,
    filter: testFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new InputClassifierNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new InputClassifierNode(nodeParams),
  },
  [MessageType.InputChoiceFromCarousel]: {
    messageType: MessageType.InputChoiceFromCarousel,
    label: NodeLabels.InputChoiceFromCarousel,
    design: () => nodeDesigns.InputChoiceFromCarousel,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new InputChoiceFromCarouselLayout(
        new InputChoiceFromCarouselNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new InputChoiceFromCarouselNode(nodeParams),
  },
  [MessageType.ListFromCarousel]: {
    messageType: MessageType.ListFromCarousel,
    label: NodeLabels.ListFromCarousel,
    design: () => nodeDesigns.ItemList,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new ItemListLayout(new ListFromCarouselNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new ListFromCarouselNode(nodeParams),
  },
  [MessageType.DefineSummary]: {
    messageType: MessageType.DefineSummary,
    label: NodeLabels.DefineSummary,
    design: () => nodeDesigns.DefineSummary,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefineSummaryLayout(new DefineSummaryNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new DefineSummaryNode(nodeParams),
  },
  [MessageType.InputCurrency]: {
    messageType: MessageType.InputCurrency,
    label: NodeLabels.InputCurrency,
    design: () => nodeDesigns.InputCurrency,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new InputCurrencyLayout(new InputCurrencyNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new InputCurrencyNode(nodeParams),
  },
  [MessageType.Sweden_FollowUp_Decrypt]: {
    messageType: MessageType.Sweden_FollowUp_Decrypt,
    label: NodeLabels.DecryptValue,
    design: () => nodeDesigns.DecryptValue,
    filter: {
      include: [
        new Area(Country.Sweden, BusinessArea.Private, LineOfBusiness.FollowUp),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new DecryptLayout(new Sweden_FollowUp_DecryptNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Sweden_FollowUp_DecryptNode(nodeParams),
  },
  [MessageType.Common_SendData]: {
    messageType: MessageType.Common_SendData,
    label: NodeLabels.CommonSendData,
    design: () => nodeDesigns.CommonFrontenSendData,
    filter: {
      include: [commonArea],
      exclude: [
        new Area(Country.Norway, BusinessArea.Private, LineOfBusiness.Motor),
        new Area(
          Country.Norway,
          BusinessArea.Commercial,
          LineOfBusiness.WorkersCompensation
        ),
        new Area(Country.Denmark, BusinessArea.Private, LineOfBusiness.Motor),
        new Area(
          Country.Denmark,
          BusinessArea.Private,
          LineOfBusiness.PersonalInjury
        ),
        new Area(Country.Sweden, BusinessArea.Private, LineOfBusiness.Motor),
        new Area(Country.Sweden, BusinessArea.Commercial, LineOfBusiness.Motor),
        new Area(Country.Sweden, BusinessArea.Private, LineOfBusiness.Property),
        new Area(
          Country.Sweden,
          BusinessArea.Private,
          LineOfBusiness.PersonalInjury
        ),
        new Area(Country.Sweden, BusinessArea.Private, LineOfBusiness.FollowUp),
        new Area(
          Country.Finland,
          BusinessArea.Commercial,
          LineOfBusiness.Motor
        ),
        new Area(Country.Finland, BusinessArea.Private, LineOfBusiness.Motor),
        new Area(
          Country.Finland,
          BusinessArea.Commercial,
          LineOfBusiness.PersonalInjury
        ),
        new Area(
          Country.Finland,
          BusinessArea.Private,
          LineOfBusiness.HealthNavigator
        ),
        new Area(
          Country.Finland,
          BusinessArea.Commercial,
          LineOfBusiness.ProfessionalAthletes
        ),
        new Area(
          Country.Finland,
          BusinessArea.Commercial,
          LineOfBusiness.PersonalInjury
        ),
        new Area(Country.Finland, BusinessArea.Private, LineOfBusiness.Travel),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new CommonSendDataLayout(new Common_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Common_SendDataNode(nodeParams),
  },
  [MessageType.Summary]: {
    messageType: MessageType.Summary,
    label: NodeLabels.Summary,
    design: () => nodeDesigns.Summary,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new SummaryLayout(new SummaryNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new SummaryNode(nodeParams),
  },
  [MessageType.Overview]: {
    messageType: MessageType.Overview,
    label: NodeLabels.Overview,
    design: () => nodeDesigns.Overview,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new OverviewLayout(new OverviewNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new OverviewNode(nodeParams),
  },
  [MessageType.Common_SendDataCompass]: {
    messageType: MessageType.Common_SendDataCompass,
    label: NodeLabels.CommonSendCompass,
    design: () => nodeDesigns.SendData,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(new Common_SendDataCompassNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Common_SendDataCompassNode(nodeParams),
  },
  [MessageType.Start]: {
    messageType: MessageType.Start,
    label: NodeLabels.Start,
    design: () => nodeDesigns.Start,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new StartLayout(new StartNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new StartNode(nodeParams),
  },
  [MessageType.Norway_Authentication]: {
    messageType: MessageType.Norway_Authentication,
    label: NodeLabels.Authentication,
    design: () => nodeDesigns.Authentication,
    filter: {
      include: [
        new Area(Country.Norway, BusinessArea.Common, LineOfBusiness.Common),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new AuthenticationLayout(new Norway_AuthenticationNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Norway_AuthenticationNode(nodeParams),
  },
  [MessageType.Norway_Motor_ShowVehicles]: {
    messageType: MessageType.Norway_Motor_ShowVehicles,
    label: NodeLabels.UserVehicles,
    design: () => nodeDesigns.UserVehicles,
    filter: {
      include: [
        new Area(Country.Norway, BusinessArea.Private, LineOfBusiness.Motor),
        new Area(Country.Norway, BusinessArea.Common, LineOfBusiness.Motor),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new UserVehiclesLayout(new Norway_Motor_ShowVehiclesNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Norway_Motor_ShowVehiclesNode(nodeParams),
  },
  [MessageType.Norway_Motor_GetPolicies]: {
    messageType: MessageType.Norway_Motor_GetPolicies,
    label: NodeLabels.GetUserPolicies,
    design: () => nodeDesigns.GetUserPolicies,
    filter: {
      include: [
        new Area(Country.Norway, BusinessArea.Common, LineOfBusiness.Motor),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new GetUserPoliciesLayout(new Norway_Motor_GetPoliciesNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Norway_Motor_GetPoliciesNode(nodeParams),
  },
  [MessageType.Norway_Summary]: {
    messageType: MessageType.Norway_Summary,
    label: NodeLabels.Summary,
    design: () => nodeDesigns.Summary,
    filter: testFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Norway_SummaryNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Norway_SummaryNode(nodeParams),
  },
  [MessageType.Norway_GetUserInformation]: {
    messageType: MessageType.Norway_GetUserInformation,
    label: NodeLabels.GetUserInformation,
    design: () => nodeDesigns.TellusAndWebchatGetUserInformation,
    filter: {
      include: [
        new Area(Country.Norway, BusinessArea.Common, LineOfBusiness.Common),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new GetUserInformationLayout(
        new Norway_GetUserInformationNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Norway_GetUserInformationNode(nodeParams),
  },
  [MessageType.Norway_Motor_SendData]: {
    messageType: MessageType.Norway_Motor_SendData,
    label: NodeLabels.SendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(Country.Norway, BusinessArea.Private, LineOfBusiness.Motor),
        new Area(Country.Norway, BusinessArea.Common, LineOfBusiness.Motor),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(new Norway_Motor_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Norway_Motor_SendDataNode(nodeParams),
  },
  [MessageType.Norway_Motor_GetVehicles]: {
    messageType: MessageType.Norway_Motor_GetVehicles,
    label: NodeLabels.GetUserVehicles,
    design: () => nodeDesigns.GetUserVehicles,
    filter: {
      include: [
        new Area(Country.Norway, BusinessArea.Private, LineOfBusiness.Motor),
        new Area(Country.Norway, BusinessArea.Common, LineOfBusiness.Motor),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new NorwayGetUserVehiclesLayout(
        new Norway_Motor_GetVehiclesNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Norway_Motor_GetVehiclesNode(nodeParams),
  },
  [MessageType.Norway_Motor_Commercial_SendData]: {
    messageType: MessageType.Norway_Motor_Commercial_SendData,
    label: NodeLabels.CommercialSendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(Country.Norway, BusinessArea.Commercial, LineOfBusiness.Motor),
        new Area(Country.Norway, BusinessArea.Common, LineOfBusiness.Motor),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(new Norway_Motor_Commercial_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Norway_Motor_Commercial_SendDataNode(nodeParams),
  },
  [MessageType.Norway_CarGlass_SendData]: {
    messageType: MessageType.Norway_CarGlass_SendData,
    label: NodeLabels.CarGlassSendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(Country.Norway, BusinessArea.Common, LineOfBusiness.Motor),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(new Norway_CarGlass_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Norway_CarGlass_SendDataNode(nodeParams),
  },
  [MessageType.Norway_Travel_GetPolicies]: {
    messageType: MessageType.Norway_Travel_GetPolicies,
    label: NodeLabels.GetUserPolicies,
    design: () => nodeDesigns.GetUserPolicies,
    filter: {
      include: [
        new Area(Country.Norway, BusinessArea.Private, LineOfBusiness.Travel),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new GetUserPoliciesLayout(new Norway_Travel_GetPoliciesNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Norway_Travel_GetPoliciesNode(nodeParams),
  },
  [MessageType.Norway_Travel_ShowPolicies]: {
    messageType: MessageType.Norway_Travel_ShowPolicies,
    label: NodeLabels.ShowPolicies,
    design: () => nodeDesigns.WebchatShowPolicies,
    filter: {
      include: [
        new Area(Country.Norway, BusinessArea.Private, LineOfBusiness.Travel),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new ShowUserPoliciesLayout(
        new Norway_Travel_ShowPoliciesNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Norway_Travel_ShowPoliciesNode(nodeParams),
  },
  [MessageType.Norway_Travel_Country]: {
    messageType: MessageType.Norway_Travel_Country,
    label: NodeLabels.InputCountry,
    design: () => nodeDesigns.Input,
    filter: {
      include: [
        new Area(Country.Norway, BusinessArea.Private, LineOfBusiness.Travel),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new InputAutocompleteLayout(new Norway_Travel_CountryNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Norway_Travel_CountryNode(nodeParams),
  },
  [MessageType.Norway_Travel_SendData]: {
    messageType: MessageType.Norway_Travel_SendData,
    label: NodeLabels.SendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(Country.Norway, BusinessArea.Private, LineOfBusiness.Travel),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(new Norway_Travel_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Norway_Travel_SendDataNode(nodeParams),
  },
  [MessageType.Norway_Animal_SendData]: {
    messageType: MessageType.Norway_Animal_SendData,
    label: NodeLabels.SendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(Country.Norway, BusinessArea.Private, LineOfBusiness.Animal),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(new Norway_Animal_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Norway_Animal_SendDataNode(nodeParams),
  },
  [MessageType.Norway_Animal_GetPolicies]: {
    messageType: MessageType.Norway_Animal_GetPolicies,
    label: NodeLabels.GetUserPolicies,
    design: () => nodeDesigns.GetUserPolicies,
    filter: {
      include: [
        new Area(Country.Norway, BusinessArea.Private, LineOfBusiness.Animal),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new GetUserPoliciesLayout(new Norway_Animal_GetPoliciesNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Norway_Animal_GetPoliciesNode(nodeParams),
  },
  [MessageType.Norway_Animal_ShowPolicies]: {
    messageType: MessageType.Norway_Animal_ShowPolicies,
    label: NodeLabels.ShowPolicies,
    design: () => nodeDesigns.WebchatShowPolicies,
    filter: {
      include: [
        new Area(Country.Norway, BusinessArea.Private, LineOfBusiness.Animal),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new ShowUserPoliciesLayout(
        new Norway_Animal_ShowPoliciesNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Norway_Animal_ShowPoliciesNode(nodeParams),
  },
  [MessageType.Norway_Property_Private_SendData]: {
    messageType: MessageType.Norway_Property_Private_SendData,
    label: NodeLabels.SendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(Country.Norway, BusinessArea.Private, LineOfBusiness.Property),
        new Area(Country.Norway, BusinessArea.Private, LineOfBusiness.Boat),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(new Norway_Property_Private_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Norway_Property_Private_SendDataNode(nodeParams),
  },
  [MessageType.Norway_Property_GetPolicies]: {
    messageType: MessageType.Norway_Property_GetPolicies,
    label: NodeLabels.GetUserPolicies,
    design: () => nodeDesigns.GetUserPolicies,
    filter: {
      include: [
        new Area(Country.Norway, BusinessArea.Private, LineOfBusiness.Property),
        new Area(Country.Norway, BusinessArea.Private, LineOfBusiness.Boat),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new GetUserPoliciesLayout(
        new Norway_Property_GetPoliciesNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Norway_Property_GetPoliciesNode(nodeParams),
  },
  [MessageType.Norway_Property_ShowPolicies]: {
    messageType: MessageType.Norway_Property_ShowPolicies,
    label: NodeLabels.ShowPolicies,
    design: () => nodeDesigns.WebchatShowPolicies,
    filter: {
      include: [
        new Area(Country.Norway, BusinessArea.Private, LineOfBusiness.Property),
        new Area(Country.Norway, BusinessArea.Private, LineOfBusiness.Boat),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new ShowUserPoliciesLayout(
        new Norway_Property_ShowPoliciesNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Norway_Property_ShowPoliciesNode(nodeParams),
  },
  [MessageType.Norway_SilentAuthentication]: {
    messageType: MessageType.Norway_SilentAuthentication,
    label: NodeLabels.SilentAuthentication,
    design: () => nodeDesigns.SilentAuthentication,
    filter: {
      include: [new Area(Country.Test, BusinessArea.Test, LineOfBusiness.Test)],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SilentAuthenticationLayout(
        new Norway_SilentAuthenticationNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Norway_SilentAuthenticationNode(nodeParams),
  },
  [MessageType.Splitter]: {
    messageType: MessageType.Splitter,
    label: NodeLabels.Splitter,
    design: () => nodeDesigns.Splitter,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new SplitterLayout(new SplitterNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new SplitterNode(nodeParams),
  },
  [MessageType.Denmark_Authentication]: {
    messageType: MessageType.Denmark_Authentication,
    label: NodeLabels.Authentication,
    design: () => nodeDesigns.Authentication,
    filter: {
      include: [
        new Area(Country.Denmark, BusinessArea.Common, LineOfBusiness.Common),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new AuthenticationLayout(new Denmark_AuthenticationNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Denmark_AuthenticationNode(nodeParams),
  },
  [MessageType.Denmark_Motor_ShowVehicles]: {
    messageType: MessageType.Denmark_Motor_ShowVehicles,
    label: NodeLabels.UserVehicles,
    design: () => nodeDesigns.UserVehicles,
    filter: {
      include: [
        new Area(Country.Denmark, BusinessArea.Private, LineOfBusiness.Motor),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new UserVehiclesLayout(new Denmark_Motor_ShowVehiclesNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Denmark_Motor_ShowVehiclesNode(nodeParams),
  },
  [MessageType.Denmark_Motor_SendData]: {
    messageType: MessageType.Denmark_Motor_SendData,
    label: NodeLabels.SendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(Country.Denmark, BusinessArea.Private, LineOfBusiness.Motor),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(new Denmark_Motor_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Denmark_Motor_SendDataNode(nodeParams),
  },
  [MessageType.Denmark_Summary]: {
    messageType: MessageType.Denmark_Summary,
    label: NodeLabels.Summary,
    design: () => nodeDesigns.Summary,
    filter: testFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Denmark_SummaryNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Denmark_SummaryNode(nodeParams),
  },
  [MessageType.Denmark_GetUserInformation]: {
    messageType: MessageType.Denmark_GetUserInformation,
    label: NodeLabels.GetUserInformation,
    design: () => nodeDesigns.WebchatGetUserInformation,
    filter: {
      include: [
        new Area(Country.Denmark, BusinessArea.Common, LineOfBusiness.Common),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new GetUserInformationLayout(
        new Denmark_GetUserInformationNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Denmark_GetUserInformationNode(nodeParams),
  },
  [MessageType.Denmark_Motor_GetVehicles]: {
    messageType: MessageType.Denmark_Motor_GetVehicles,
    label: NodeLabels.GetUserVehicles,
    design: () => nodeDesigns.GetUserVehicles,
    filter: {
      include: [
        new Area(Country.Denmark, BusinessArea.Private, LineOfBusiness.Motor),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new DenmarkGetUserVehiclesLayout(
        new Denmark_Motor_GetVehiclesNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Denmark_Motor_GetVehiclesNode(nodeParams),
  },
  [MessageType.Denmark_PersonalInjury_SendData]: {
    messageType: MessageType.Denmark_PersonalInjury_SendData,
    label: NodeLabels.SendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(
          Country.Denmark,
          BusinessArea.Private,
          LineOfBusiness.PersonalInjury
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(new Denmark_PersonalInjury_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Denmark_PersonalInjury_SendDataNode(nodeParams),
  },
  [MessageType.Denmark_PersonalInjury_GetUserPolicies]: {
    messageType: MessageType.Denmark_PersonalInjury_GetUserPolicies,
    label: NodeLabels.GetUserPolicies,
    design: () => nodeDesigns.GetPolicyInformation,
    filter: {
      include: [
        new Area(
          Country.Denmark,
          BusinessArea.Private,
          LineOfBusiness.PersonalInjury
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new DenmarkPersonalInjuryGetPoliciesLayout(
        new Denmark_PersonalInjury_GetUserPoliciesNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Denmark_PersonalInjury_GetUserPoliciesNode(nodeParams),
  },
  [MessageType.Denmark_PersonalInjury_ShowUserPolicies]: {
    messageType: MessageType.Denmark_PersonalInjury_ShowUserPolicies,
    label: NodeLabels.ShowPolicies,
    design: () => nodeDesigns.WebchatShowPolicies,
    filter: {
      include: [
        new Area(
          Country.Denmark,
          BusinessArea.Private,
          LineOfBusiness.PersonalInjury
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new ShowUserPoliciesLayout(
        new Denmark_PersonalInjury_ShowUserPoliciesNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Denmark_PersonalInjury_ShowUserPoliciesNode(nodeParams),
  },
  [MessageType.Denmark_Property_SendData]: {
    messageType: MessageType.Denmark_Property_SendData,
    label: NodeLabels.SendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(Country.Denmark, BusinessArea.Common, LineOfBusiness.Property),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(new Denmark_Property_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Denmark_Property_SendDataNode(nodeParams),
  },
  [MessageType.Denmark_Property_GetPolicies]: {
    messageType: MessageType.Denmark_Property_GetPolicies,
    label: NodeLabels.GetUserPolicies,
    design: () => nodeDesigns.GetUserPolicies,
    filter: {
      include: [
        new Area(Country.Denmark, BusinessArea.Common, LineOfBusiness.Property),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Denmark_Property_GetPoliciesNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Denmark_Property_GetPoliciesNode(nodeParams),
  },
  [MessageType.Denmark_Commercial_Travel_SendData]: {
    messageType: MessageType.Denmark_Commercial_Travel_SendData,
    label: NodeLabels.SendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(
          Country.Denmark,
          BusinessArea.Commercial,
          LineOfBusiness.Travel
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(
        new Denmark_Commercial_Travel_SendDataNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Denmark_Commercial_Travel_SendDataNode(nodeParams),
  },
  [MessageType.Denmark_Property_ShowPolicies]: {
    messageType: MessageType.Denmark_Property_ShowPolicies,
    label: NodeLabels.ShowPolicies,
    design: () => nodeDesigns.WebchatShowPolicies,
    filter: {
      include: [
        new Area(Country.Denmark, BusinessArea.Common, LineOfBusiness.Property),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new ShowUserPoliciesLayout(
        new Denmark_Property_ShowPoliciesNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Denmark_Property_ShowPoliciesNode(nodeParams),
  },
  [MessageType.Denmark_SilentAuthentication]: {
    messageType: MessageType.Denmark_SilentAuthentication,
    label: NodeLabels.SilentAuthentication,
    design: () => nodeDesigns.SilentAuthentication,
    filter: {
      include: [new Area(Country.Test, BusinessArea.Test, LineOfBusiness.Test)],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SilentAuthenticationLayout(
        new Denmark_SilentAuthenticationNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Denmark_SilentAuthenticationNode(nodeParams),
  },
  [MessageType.Finland_Authentication]: {
    messageType: MessageType.Finland_Authentication,
    label: NodeLabels.Authentication,
    design: () => nodeDesigns.Authentication,
    filter: {
      include: [
        new Area(Country.Finland, BusinessArea.Common, LineOfBusiness.Common),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new AuthenticationLayout(new Finland_AuthenticationNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Finland_AuthenticationNode(nodeParams),
  },
  [MessageType.Finland_Motor_ShowVehicles]: {
    messageType: MessageType.Finland_Motor_ShowVehicles,
    label: NodeLabels.UserVehicles,
    design: () => nodeDesigns.UserVehicles,
    filter: {
      include: [
        new Area(Country.Finland, BusinessArea.Common, LineOfBusiness.Motor),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new UserVehiclesLayout(new Finland_Motor_ShowVehiclesNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Finland_Motor_ShowVehiclesNode(nodeParams),
  },
  [MessageType.Finland_Motor_SendData]: {
    messageType: MessageType.Finland_Motor_SendData,
    label: NodeLabels.SendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(
          Country.Finland,
          BusinessArea.Commercial,
          LineOfBusiness.Motor
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(new Finland_Motor_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Finland_Motor_SendDataNode(nodeParams),
  },
  [MessageType.Finland_Summary]: {
    messageType: MessageType.Finland_Summary,
    label: NodeLabels.Summary,
    design: () => nodeDesigns.Summary,
    filter: testFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Finland_SummaryNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Finland_SummaryNode(nodeParams),
  },
  [MessageType.Finland_Motor_GetPolicies]: {
    messageType: MessageType.Finland_Motor_GetPolicies,
    label: NodeLabels.GetUserPolicies,
    design: () => nodeDesigns.GetUserPolicies,
    filter: {
      include: [
        new Area(Country.Finland, BusinessArea.Common, LineOfBusiness.Motor),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new GetUserPoliciesLayout(new Finland_Motor_GetPoliciesNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Finland_Motor_GetPoliciesNode(nodeParams),
  },
  [MessageType.Finland_InputCity]: {
    messageType: MessageType.Finland_InputCity,
    label: NodeLabels.InputCity,
    design: () => nodeDesigns.ClassifierDropdown,
    filter: {
      include: [
        new Area(Country.Finland, BusinessArea.Common, LineOfBusiness.Common),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new FinlandInputCityLayout(new Finland_InputCityNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Finland_InputCityNode(nodeParams),
  },
  [MessageType.Finland_MyPagesAuthentication]: {
    messageType: MessageType.Finland_MyPagesAuthentication,
    label: NodeLabels.MyPagesAuthentication,
    design: () => nodeDesigns.Authentication,
    filter: {
      include: [
        new Area(Country.Finland, BusinessArea.Common, LineOfBusiness.Common),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new MyPagesAuthenticationLayout(
        new Finland_MyPagesAuthenticationNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Finland_MyPagesAuthenticationNode(nodeParams),
  },
  [MessageType.Finland_InputBankAccount]: {
    messageType: MessageType.Finland_InputBankAccount,
    label: NodeLabels.InputBankAccount,
    design: () => nodeDesigns.InputBankAccount,
    filter: {
      include: [
        new Area(Country.Finland, BusinessArea.Common, LineOfBusiness.Common),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new InputBankAccountLayout(new Finland_InputBankAccountNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Finland_InputBankAccountNode(nodeParams),
  },
  [MessageType.Finland_InputPostalCode]: {
    messageType: MessageType.Finland_InputPostalCode,
    label: NodeLabels.InputPostalCode,
    design: () => nodeDesigns.ClassifierDropdown,
    filter: {
      include: [
        new Area(Country.Finland, BusinessArea.Common, LineOfBusiness.Common),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new InputAutocompleteLayout(new Finland_InputPostalCodeNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Finland_InputPostalCodeNode(nodeParams),
  },
  [MessageType.Finland_MTPL_SendData]: {
    messageType: MessageType.Finland_MTPL_SendData,
    label: NodeLabels.SendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(Country.Finland, BusinessArea.Private, LineOfBusiness.Motor),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(new Finland_MTPL_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Finland_MTPL_SendDataNode(nodeParams),
  },
  [MessageType.Finland_Into_SendData]: {
    messageType: MessageType.Finland_Into_SendData,
    label: NodeLabels.IntoSendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(Country.Finland, BusinessArea.Common, LineOfBusiness.Common),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(new Finland_Into_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Finland_Into_SendDataNode(nodeParams),
  },
  [MessageType.Finland_SilentAuthentication]: {
    messageType: MessageType.Finland_SilentAuthentication,
    label: NodeLabels.SilentAuthentication,
    design: () => nodeDesigns.SilentAuthentication,
    filter: {
      include: [new Area(Country.Test, BusinessArea.Test, LineOfBusiness.Test)],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SilentAuthenticationLayout(
        new Finland_SilentAuthenticationNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Finland_SilentAuthenticationNode(nodeParams),
  },
  [MessageType.Sweden_Authentication]: {
    messageType: MessageType.Sweden_Authentication,
    label: NodeLabels.Authentication,
    design: () => nodeDesigns.Authentication,
    filter: {
      include: [
        new Area(Country.Sweden, BusinessArea.Common, LineOfBusiness.Common),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new AuthenticationLayout(new Sweden_AuthenticationNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Sweden_AuthenticationNode(nodeParams),
  },
  [MessageType.Sweden_Motor_SendData]: {
    messageType: MessageType.Sweden_Motor_SendData,
    label: NodeLabels.SendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(Country.Sweden, BusinessArea.Private, LineOfBusiness.Motor),
        new Area(Country.Sweden, BusinessArea.Commercial, LineOfBusiness.Motor),
        new Area(Country.Sweden, BusinessArea.Common, LineOfBusiness.Motor),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(new Sweden_Motor_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Sweden_Motor_SendDataNode(nodeParams),
  },
  [MessageType.Sweden_Summary]: {
    messageType: MessageType.Sweden_Summary,
    label: NodeLabels.Summary,
    design: () => nodeDesigns.Summary,
    filter: testFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Sweden_SummaryNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Sweden_SummaryNode(nodeParams),
  },
  [MessageType.Common_Motor_GetPolicies]: {
    messageType: MessageType.Common_Motor_GetPolicies,
    label: NodeLabels.CommonGetMotorPolicies,
    design: () => nodeDesigns.TellusGetUserPolicies,
    filter: {
      include: [
        new Area(Country.Common, BusinessArea.Common, LineOfBusiness.Motor),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new GetMotorPoliciesLayout(new Common_Motor_GetPoliciesNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Common_Motor_GetPoliciesNode(nodeParams),
  },
  [MessageType.Sweden_InputCountry]: {
    messageType: MessageType.Sweden_InputCountry,
    label: NodeLabels.InputCountry,
    design: () => nodeDesigns.Input,
    filter: {
      include: [
        new Area(Country.Sweden, BusinessArea.Common, LineOfBusiness.Common),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new InputAutocompleteLayout(new Sweden_InputCountryNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Sweden_InputCountryNode(nodeParams),
  },
  [MessageType.Sweden_Property_SendData]: {
    messageType: MessageType.Sweden_Property_SendData,
    label: NodeLabels.SendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(Country.Sweden, BusinessArea.Private, LineOfBusiness.Property),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(new Sweden_Property_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Sweden_Property_SendDataNode(nodeParams),
  },
  [MessageType.Sweden_Property_GetPolicies]: {
    messageType: MessageType.Sweden_Property_GetPolicies,
    label: NodeLabels.GetUserPolicies,
    design: () => nodeDesigns.GetUserPolicies,
    filter: {
      include: [
        new Area(Country.Sweden, BusinessArea.Private, LineOfBusiness.Property),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new GetUserPoliciesLayout(
        new Sweden_Property_GetPoliciesNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Sweden_Property_GetPoliciesNode(nodeParams),
  },
  [MessageType.Sweden_PersonalInjury_SendData]: {
    messageType: MessageType.Sweden_PersonalInjury_SendData,
    label: NodeLabels.SendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(
          Country.Sweden,
          BusinessArea.Private,
          LineOfBusiness.PersonalInjury
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(new Sweden_PersonalInjury_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Sweden_PersonalInjury_SendDataNode(nodeParams),
  },
  [MessageType.Sweden_FollowUp_SendData]: {
    messageType: MessageType.Sweden_FollowUp_SendData,
    label: NodeLabels.SendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(Country.Sweden, BusinessArea.Private, LineOfBusiness.FollowUp),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(new Sweden_FollowUp_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Sweden_FollowUp_SendDataNode(nodeParams),
  },
  [MessageType.Sweden_SilentAuthentication]: {
    messageType: MessageType.Sweden_SilentAuthentication,
    label: NodeLabels.SilentAuthentication,
    design: () => nodeDesigns.SilentAuthentication,
    filter: {
      include: [new Area(Country.Test, BusinessArea.Test, LineOfBusiness.Test)],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SilentAuthenticationLayout(
        new Sweden_SilentAuthenticationNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Sweden_SilentAuthenticationNode(nodeParams),
  },
  [MessageType.Ba_InputAttachment]: {
    messageType: MessageType.Ba_InputAttachment,
    label: BalticNodeLabels.InputAttachment,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_InputAttachmentNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Ba_InputAttachmentNode(nodeParams),
  },
  [MessageType.Ba_SendData]: {
    messageType: MessageType.Ba_SendData,
    label: BalticNodeLabels.SendData,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Ba_SendDataNode(nodeParams),
  },
  [MessageType.Ba_Map]: {
    messageType: MessageType.Ba_Map,
    label: BalticNodeLabels.Map,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_MapNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Ba_MapNode(nodeParams),
  },
  [MessageType.Ba_Summary]: {
    messageType: MessageType.Ba_Summary,
    label: BalticNodeLabels.Summary,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_SummaryNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Ba_SummaryNode(nodeParams),
  },
  [MessageType.Ba_CheckAuthentication]: {
    messageType: MessageType.Ba_CheckAuthentication,
    label: BalticNodeLabels.CheckAuthentication,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_CheckAuthenticationNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Ba_CheckAuthenticationNode(nodeParams),
  },
  [MessageType.Ba_Authentication]: {
    messageType: MessageType.Ba_Authentication,
    label: BalticNodeLabels.Authentication,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_AuthenticationNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Ba_AuthenticationNode(nodeParams),
  },
  [MessageType.Ba_GetCustomerInformation]: {
    messageType: MessageType.Ba_GetCustomerInformation,
    label: BalticNodeLabels.GetCustomerInformation,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_GetCustomerInformationNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Ba_GetCustomerInformationNode(nodeParams),
  },
  [MessageType.Ba_BankAccount]: {
    messageType: MessageType.Ba_BankAccount,
    label: BalticNodeLabels.BankAccount,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_BankAccountNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Ba_BankAccountNode(nodeParams),
  },
  [MessageType.Ba_GetUserPolicies]: {
    messageType: MessageType.Ba_GetUserPolicies,
    label: BalticNodeLabels.GetUserPolicies,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_GetUserPoliciesNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Ba_GetUserPoliciesNode(nodeParams),
  },
  [MessageType.Ba_GetUserInsuredPersons]: {
    messageType: MessageType.Ba_GetUserInsuredPersons,
    label: BalticNodeLabels.GetUserInsuredPersons,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_GetUserInsuredPersonsNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Ba_GetUserInsuredPersonsNode(nodeParams),
  },
  [MessageType.Ba_UserInsuredPersons]: {
    messageType: MessageType.Ba_UserInsuredPersons,
    label: BalticNodeLabels.UserInsuredPersons,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_UserInsuredPersonsNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Ba_UserInsuredPersonsNode(nodeParams),
  },
  [MessageType.Ba_CustomDropdown]: {
    messageType: MessageType.Ba_CustomDropdown,
    label: BalticNodeLabels.CustomDropdown,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_CustomDropdownNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Ba_CustomDropdownNode(nodeParams),
  },
  [MessageType.Ba_GetInsuredPersonsList]: {
    messageType: MessageType.Ba_GetInsuredPersonsList,
    label: BalticNodeLabels.GetInsuredPersonsList,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_GetInsuredPersonsListNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Ba_GetInsuredPersonsListNode(nodeParams),
  },
  [MessageType.Ba_TotalAmount]: {
    messageType: MessageType.Ba_TotalAmount,
    label: BalticNodeLabels.TotalAmount,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_TotalAmountNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Ba_TotalAmountNode(nodeParams),
  },
  [MessageType.Ba_GetUserVehicles]: {
    messageType: MessageType.Ba_GetUserVehicles,
    label: BalticNodeLabels.GetUserVehicles,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_GetUserVehiclesNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Ba_GetUserVehiclesNode(nodeParams),
  },
  [MessageType.Ba_UserVehicles]: {
    messageType: MessageType.Ba_UserVehicles,
    label: BalticNodeLabels.UserVehicles,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_UserVehiclesNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Ba_UserVehiclesNode(nodeParams),
  },
  [MessageType.Ba_AccidentAct]: {
    messageType: MessageType.Ba_AccidentAct,
    label: BalticNodeLabels.AccidentAct,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_AccidentActNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Ba_AccidentActNode(nodeParams),
  },
  [MessageType.Ba_DriverDetails]: {
    messageType: MessageType.Ba_DriverDetails,
    label: BalticNodeLabels.DriverDetails,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_DriverDetailsNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Ba_DriverDetailsNode(nodeParams),
  },
  [MessageType.Ba_SecondVehicle]: {
    messageType: MessageType.Ba_SecondVehicle,
    label: BalticNodeLabels.SecondVehicle,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_SecondVehicleNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Ba_SecondVehicleNode(nodeParams),
  },
  [MessageType.Ba_PoliceStation]: {
    messageType: MessageType.Ba_PoliceStation,
    label: BalticNodeLabels.PoliceStation,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_PoliceStationNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Ba_PoliceStationNode(nodeParams),
  },
  [MessageType.Ba_Steering]: {
    messageType: MessageType.Ba_Steering,
    label: BalticNodeLabels.Steering,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_SteeringNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Ba_SteeringNode(nodeParams),
  },
  [MessageType.Ba_GetUserObjects]: {
    messageType: MessageType.Ba_GetUserObjects,
    label: BalticNodeLabels.GetUserObjects,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_GetUserObjectsNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Ba_GetUserObjectsNode(nodeParams),
  },
  [MessageType.Ba_UserObjects]: {
    messageType: MessageType.Ba_UserObjects,
    label: BalticNodeLabels.UserObjects,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_UserObjectsNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Ba_UserObjectsNode(nodeParams),
  },
  [MessageType.Ba_DamagedObject]: {
    messageType: MessageType.Ba_DamagedObject,
    label: BalticNodeLabels.DamagedObject,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_DamagedObjectNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Ba_DamagedObjectNode(nodeParams),
  },

  [MessageType.Ba_GetUserPets]: {
    messageType: MessageType.Ba_GetUserPets,
    label: BalticNodeLabels.GetUserPets,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_GetUserPetsNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Ba_GetUserPetsNode(nodeParams),
  },
  [MessageType.Ba_UserPets]: {
    messageType: MessageType.Ba_UserPets,
    label: BalticNodeLabels.UserPets,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_UserPetsNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Ba_UserPetsNode(nodeParams),
  },
  [MessageType.Ba_CreditCardType]: {
    messageType: MessageType.Ba_CreditCardType,
    label: BalticNodeLabels.CreditCardType,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_CreditCardTypeNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Ba_CreditCardTypeNode(nodeParams),
  },
  [MessageType.Ba_SufferedPersons]: {
    messageType: MessageType.Ba_SufferedPersons,
    label: BalticNodeLabels.SufferedPersons,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_SufferedPersonsNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Ba_SufferedPersonsNode(nodeParams),
  },
  [MessageType.Ba_TravelInputCountry]: {
    messageType: MessageType.Ba_TravelInputCountry,
    label: BalticNodeLabels.TravelInputCountry,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_TravelInputCountryNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Ba_TravelInputCountryNode(nodeParams),
  },
  [MessageType.Ba_HealthInputAttachment]: {
    messageType: MessageType.Ba_HealthInputAttachment,
    label: BalticNodeLabels.HealthInputAttachment,
    design: () => nodeDesigns.Baltic,
    filter: balticFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Ba_HealthInputAttachmentNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Ba_HealthInputAttachmentNode(nodeParams),
  },
  [MessageType.Finland_PersonalInjury_SendData]: {
    messageType: MessageType.Finland_PersonalInjury_SendData,
    label: NodeLabels.SendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(
          Country.Finland,
          BusinessArea.Private,
          LineOfBusiness.PersonalInjury
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(new Finland_PersonalInjury_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Finland_PersonalInjury_SendDataNode(nodeParams),
  },
  [MessageType.Finland_PersonalInjury_SportCode]: {
    messageType: MessageType.Finland_PersonalInjury_SportCode,
    label: NodeLabels.SportCode,
    design: () => nodeDesigns.Input,
    filter: {
      include: [
        new Area(
          Country.Finland,
          BusinessArea.Private,
          LineOfBusiness.PersonalInjury
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new InputAutocompleteLayout(
        new Finland_PersonalInjury_SportCodeNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Finland_PersonalInjury_SportCodeNode(nodeParams),
  },
  [MessageType.Finland_PersonalInjury_DiagnosisCode]: {
    messageType: MessageType.Finland_PersonalInjury_DiagnosisCode,
    label: NodeLabels.DiagnosisCode,
    design: () => nodeDesigns.Input,
    filter: {
      include: [
        new Area(
          Country.Finland,
          BusinessArea.Private,
          LineOfBusiness.PersonalInjury
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new InputAutocompleteLayout(
        new Finland_PersonalInjury_DiagnosisCodeNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Finland_PersonalInjury_DiagnosisCodeNode(nodeParams),
  },
  [MessageType.Finland_PersonalInjury_MedicineCode]: {
    messageType: MessageType.Finland_PersonalInjury_MedicineCode,
    label: NodeLabels.MedicineCode,
    design: () => nodeDesigns.Default,
    filter: {
      include: [
        new Area(
          Country.Finland,
          BusinessArea.Private,
          LineOfBusiness.PersonalInjury
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new InputAutocompleteLayout(
        new Finland_PersonalInjury_MedicineCodeNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Finland_PersonalInjury_MedicineCodeNode(nodeParams),
  },
  [MessageType.Finland_PersonalInjury_BodyPartCode]: {
    messageType: MessageType.Finland_PersonalInjury_BodyPartCode,
    label: NodeLabels.BodyPartCode,
    design: () => nodeDesigns.Default,
    filter: {
      include: [
        new Area(
          Country.Finland,
          BusinessArea.Private,
          LineOfBusiness.PersonalInjury
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new InputAutocompleteLayout(
        new Finland_PersonalInjury_BodyPartCodeNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Finland_PersonalInjury_BodyPartCodeNode(nodeParams),
  },
  [MessageType.Finland_PersonalInjury_TradeUnionCode]: {
    messageType: MessageType.Finland_PersonalInjury_TradeUnionCode,
    label: NodeLabels.TradeUnionCode,
    design: () => nodeDesigns.Default,
    filter: {
      include: [
        new Area(
          Country.Finland,
          BusinessArea.Private,
          LineOfBusiness.PersonalInjury
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new InputAutocompleteLayout(
        new Finland_PersonalInjury_TradeUnionCodeNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Finland_PersonalInjury_TradeUnionCodeNode(nodeParams),
  },
  [MessageType.Finland_HealthNavigator_ClaimLiabilitySearch]: {
    messageType: MessageType.Finland_HealthNavigator_ClaimLiabilitySearch,
    label: NodeLabels.ClaimLiabilitySearch,
    design: () => nodeDesigns.Default,
    filter: {
      include: [
        new Area(
          Country.Finland,
          BusinessArea.Private,
          LineOfBusiness.HealthNavigator
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new VerifyCoverageLayout(
        new Finland_HealthNavigator_ClaimLiabilitySearchNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Finland_HealthNavigator_ClaimLiabilitySearchNode(nodeParams),
  },
  [MessageType.Finland_HealthNavigator_SendData]: {
    messageType: MessageType.Finland_HealthNavigator_SendData,
    label: NodeLabels.SendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(
          Country.Finland,
          BusinessArea.Private,
          LineOfBusiness.HealthNavigator
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(new Finland_HealthNavigator_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Finland_HealthNavigator_SendDataNode(nodeParams),
  },
  [MessageType.Finland_PersonalInjury_GetInsuredPeople]: {
    messageType: MessageType.Finland_PersonalInjury_GetInsuredPeople,
    label: NodeLabels.GetInsuredPeople,
    design: () => nodeDesigns.Default,
    filter: {
      include: [
        new Area(
          Country.Finland,
          BusinessArea.Private,
          LineOfBusiness.PersonalInjury
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new GetInsuredPeopleLayout(
        new Finland_PersonalInjury_GetInsuredPeopleNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Finland_PersonalInjury_GetInsuredPeopleNode(nodeParams),
  },
  [MessageType.Finland_PersonalInjury_ShowInsuredPeople]: {
    messageType: MessageType.Finland_PersonalInjury_ShowInsuredPeople,
    label: NodeLabels.InsuredPeople,
    design: () => nodeDesigns.Default,
    filter: {
      include: [
        new Area(
          Country.Finland,
          BusinessArea.Private,
          LineOfBusiness.PersonalInjury
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new InsuredPeopleLayout(
        new Finland_PersonalInjury_ShowInsuredPeopleNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Finland_PersonalInjury_ShowInsuredPeopleNode(nodeParams),
  },
  [MessageType.Finland_WorkersCompensation_GetPolicies]: {
    messageType: MessageType.Finland_WorkersCompensation_GetPolicies,
    label: NodeLabels.GetUserPolicies,
    design: () => nodeDesigns.TellusGetUserPolicies,
    filter: {
      include: [
        new Area(
          Country.Finland,
          BusinessArea.Commercial,
          LineOfBusiness.PersonalInjury
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new GetUserPoliciesLayout(
        new Finland_WorkersCompensation_GetPoliciesNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Finland_WorkersCompensation_GetPoliciesNode(nodeParams),
  },
  [MessageType.Finland_WorkersCompensation_ShowPolicies]: {
    messageType: MessageType.Finland_WorkersCompensation_ShowPolicies,
    label: NodeLabels.ShowPolicies,
    design: () => nodeDesigns.TellusAndWebchatShowPolicies,
    filter: {
      include: [
        new Area(
          Country.Finland,
          BusinessArea.Commercial,
          LineOfBusiness.PersonalInjury
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new ShowUserPoliciesLayout(
        new Finland_WorkersCompensation_ShowPoliciesNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Finland_WorkersCompensation_ShowPoliciesNode(nodeParams),
  },
  [MessageType.Finland_WorkersCompensation_ShowOccupationalClasses]: {
    messageType:
      MessageType.Finland_WorkersCompensation_ShowOccupationalClasses,
    label: NodeLabels.ShowOccupationalClasses,
    design: () => nodeDesigns.TellusAndWebchatShowPolicies,
    filter: {
      include: [
        new Area(
          Country.Finland,
          BusinessArea.Commercial,
          LineOfBusiness.PersonalInjury
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new FinlandWorkersCompShowOccupationalClassesLayout(
        new Finland_WorkersCompensation_ShowOccupationalClassesNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Finland_WorkersCompensation_ShowOccupationalClassesNode(nodeParams),
  },
  [MessageType.Finland_WorkersCompensation_ShowDepartments]: {
    messageType: MessageType.Finland_WorkersCompensation_ShowDepartments,
    label: NodeLabels.ShowDepartments,
    design: () => nodeDesigns.TellUsClassifierDropdown,
    filter: {
      include: [
        new Area(
          Country.Finland,
          BusinessArea.Commercial,
          LineOfBusiness.PersonalInjury
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new FinlandWorkersCompShowDepartmentsLayout(
        new Finland_WorkersCompensation_ShowDepartmentsNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Finland_WorkersCompensation_ShowDepartmentsNode(nodeParams),
  },
  [MessageType.Finland_WorkersCompensation_ShowProducts]: {
    messageType: MessageType.Finland_WorkersCompensation_ShowProducts,
    label: NodeLabels.ShowProducts,
    design: () => nodeDesigns.TellusShowPolicies,
    filter: {
      include: [
        new Area(
          Country.Finland,
          BusinessArea.Commercial,
          LineOfBusiness.PersonalInjury
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new FinlandWorkersCompShowProductsLayout(
        new Finland_WorkersCompensation_ShowProductsNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Finland_WorkersCompensation_ShowProductsNode(nodeParams),
  },
  [MessageType.Finland_WorkersCompensation_GetOccupationalClasses]: {
    messageType: MessageType.Finland_WorkersCompensation_GetOccupationalClasses,
    label: NodeLabels.GetOccupationalClasses,
    design: () => nodeDesigns.TellusShowPolicies,
    filter: {
      include: [
        new Area(
          Country.Finland,
          BusinessArea.Commercial,
          LineOfBusiness.PersonalInjury
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new FinlandWorkersCompGetOccupationalClassesLayout(
        new Finland_WorkersCompensation_GetOccupationalClassesNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Finland_WorkersCompensation_GetOccupationalClassesNode(nodeParams),
  },
  [MessageType.Finland_WorkersCompensation_GetUserInformation]: {
    messageType: MessageType.Finland_WorkersCompensation_GetUserInformation,
    label: NodeLabels.GetUserInformation,
    design: () => nodeDesigns.FI_WorkersComp_GetUserInformation,
    filter: {
      include: [
        new Area(
          Country.Finland,
          BusinessArea.Commercial,
          LineOfBusiness.PersonalInjury
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new GetUserInformationLayout(
        new Finland_WorkersCompensation_GetUserInformationNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Finland_WorkersCompensation_GetUserInformationNode(nodeParams),
  },
  [MessageType.Finland_WorkersCompensation_ShowExposures]: {
    messageType: MessageType.Finland_WorkersCompensation_ShowExposures,
    label: NodeLabels.ShowExposures,
    design: () => nodeDesigns.TellusAndWebchatShowPolicies,
    filter: {
      include: [
        new Area(
          Country.Finland,
          BusinessArea.Commercial,
          LineOfBusiness.PersonalInjury
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new FinlandWorkersCompShowExposuresLayout(
        new Finland_WorkersCompensation_ShowExposuresNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Finland_WorkersCompensation_ShowExposuresNode(nodeParams),
  },
  [MessageType.Finland_WorkersCompensation_GetExposures]: {
    messageType: MessageType.Finland_WorkersCompensation_GetExposures,
    label: NodeLabels.GetExposures,
    design: () => nodeDesigns.TellusShowPolicies,
    filter: {
      include: [
        new Area(
          Country.Finland,
          BusinessArea.Commercial,
          LineOfBusiness.PersonalInjury
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new FinlandWorkersCompGetExposuresLayout(
        new Finland_WorkersCompensation_GetExposuresNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Finland_WorkersCompensation_GetExposuresNode(nodeParams),
  },
  [MessageType.GetSubmissionRelation]: {
    messageType: MessageType.GetSubmissionRelation,
    label: NodeLabels.GetSubmissionRelation,
    design: () => nodeDesigns.GetSubmissionRelation,
    filter: {
      include: [
        new Area(
          Country.Finland,
          BusinessArea.Commercial,
          LineOfBusiness.PersonalInjury
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new GetSubmissionRelationLayout(
        new GetSubmissionRelationNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new GetSubmissionRelationNode(nodeParams),
  },
  [MessageType.Finland_WorkersCompensation_SendData]: {
    messageType: MessageType.Finland_WorkersCompensation_SendData,
    label: NodeLabels.SendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(
          Country.Finland,
          BusinessArea.Commercial,
          LineOfBusiness.PersonalInjury
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(
        new Finland_WorkersCompensation_SendDataNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Finland_WorkersCompensation_SendDataNode(nodeParams),
  },
  [MessageType.Finland_Animal_CatBreeds]: {
    messageType: MessageType.Finland_Animal_CatBreeds,
    label: NodeLabels.CatBreeds,
    design: () => nodeDesigns.Default,
    filter: {
      include: [
        new Area(Country.Finland, BusinessArea.Common, LineOfBusiness.Common),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new InputAutocompleteLayout(new Finland_Animal_CatBreedsNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Finland_Animal_CatBreedsNode(nodeParams),
  },
  [MessageType.Finland_Animal_SendData]: {
    messageType: MessageType.Finland_Animal_SendData,
    label: NodeLabels.SendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(Country.Finland, BusinessArea.Common, LineOfBusiness.Animal),
      ],
      exclude: [
        new Area(
          Country.Finland,
          BusinessArea.Commercial,
          LineOfBusiness.PersonalInjury
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(new Finland_Animal_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Finland_Animal_SendDataNode(nodeParams),
  },
  [MessageType.Finland_Animal_DogBreeds]: {
    messageType: MessageType.Finland_Animal_DogBreeds,
    label: NodeLabels.DogBreeds,
    design: () => nodeDesigns.Default,
    filter: {
      include: [
        new Area(Country.Finland, BusinessArea.Common, LineOfBusiness.Common),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new InputAutocompleteLayout(new Finland_Animal_DogBreedsNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Finland_Animal_DogBreedsNode(nodeParams),
  },
  [MessageType.Finland_Animal_AnimalDiagnosis]: {
    messageType: MessageType.Finland_Animal_AnimalDiagnosis,
    label: NodeLabels.AnimalDiagnosis,
    design: () => nodeDesigns.Default,
    filter: {
      include: [
        new Area(Country.Finland, BusinessArea.Common, LineOfBusiness.Common),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new InputAutocompleteLayout(
        new Finland_Animal_AnimalDiagnosisNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Finland_Animal_AnimalDiagnosisNode(nodeParams),
  },
  [MessageType.Finland_Animal_GetPolicies]: {
    messageType: MessageType.Finland_Animal_GetPolicies,
    label: NodeLabels.GetUserPolicies,
    design: () => nodeDesigns.GetUserPolicies,
    filter: {
      include: [
        new Area(Country.Finland, BusinessArea.Common, LineOfBusiness.Animal),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new FinlandAnimalGetPoliciesLayout(
        new Finland_Animal_GetPoliciesNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Finland_Animal_GetPoliciesNode(nodeParams),
  },
  [MessageType.Finland_Animal_ShowPolicies]: {
    messageType: MessageType.Finland_Animal_ShowPolicies,
    label: NodeLabels.ShowPolicies,
    design: () => nodeDesigns.WebchatShowPolicies,
    filter: {
      include: [
        new Area(Country.Finland, BusinessArea.Common, LineOfBusiness.Animal),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new FinlandAnimalShowPoliciesLayout(
        new Finland_Animal_ShowPoliciesNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Finland_Animal_ShowPoliciesNode(nodeParams),
  },
  [MessageType.Finland_Property_SendData]: {
    messageType: MessageType.Finland_Property_SendData,
    label: NodeLabels.SendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(Country.Finland, BusinessArea.Common, LineOfBusiness.Property),
      ],
      exclude: [
        new Area(
          Country.Finland,
          BusinessArea.Commercial,
          LineOfBusiness.PersonalInjury
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(new Finland_Property_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Finland_Property_SendDataNode(nodeParams),
  },
  [MessageType.Test_Country]: {
    messageType: MessageType.Test_Country,
    label: NodeLabels.InputCountry,
    design: () => nodeDesigns.Default,
    filter: testFilter,
    createLayout: (nodeParams: NodeParams) =>
      new DefaultLayout(new Test_CountryNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new Test_CountryNode(nodeParams),
  },
  [MessageType.Norway_PersonalInjury_Commercial_GetPolicies]: {
    messageType: MessageType.Norway_PersonalInjury_Commercial_GetPolicies,
    label: NodeLabels.GetUserPolicies,
    design: () => nodeDesigns.GetUserPolicies,
    filter: {
      include: [
        new Area(
          Country.Norway,
          BusinessArea.Commercial,
          LineOfBusiness.WorkersCompensation
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new GetUserPoliciesLayout(
        new Norway_PersonalInjury_Commercial_GetPoliciesNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Norway_PersonalInjury_Commercial_GetPoliciesNode(nodeParams),
  },
  [MessageType.Norway_PersonalInjury_Commercial_SendData]: {
    messageType: MessageType.Norway_PersonalInjury_Commercial_SendData,
    label: NodeLabels.SendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(
          Country.Norway,
          BusinessArea.Commercial,
          LineOfBusiness.WorkersCompensation
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(
        new Norway_PersonalInjury_Commercial_SendDataNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Norway_PersonalInjury_Commercial_SendDataNode(nodeParams),
  },
  [MessageType.Norway_Expatriates_Country]: {
    messageType: MessageType.Norway_Expatriates_Country,
    label: NodeLabels.InputCountry,
    design: () => nodeDesigns.Input,
    filter: {
      include: [
        new Area(Country.Norway, BusinessArea.Private, LineOfBusiness.Expat),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new InputAutocompleteLayout(
        new Norway_Expatriates_CountryNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Norway_Expatriates_CountryNode(nodeParams),
  },
  [MessageType.Norway_Expatriates_SendData]: {
    messageType: MessageType.Norway_Expatriates_SendData,
    label: NodeLabels.SendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(Country.Norway, BusinessArea.Common, LineOfBusiness.Expat),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(new Norway_Expatriates_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Norway_Expatriates_SendDataNode(nodeParams),
  },
  [MessageType.Finland_Athletes_SendData]: {
    messageType: MessageType.Finland_Athletes_SendData,
    label: NodeLabels.SendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(
          Country.Finland,
          BusinessArea.Commercial,
          LineOfBusiness.ProfessionalAthletes
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(new Finland_Athletes_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Finland_Athletes_SendDataNode(nodeParams),
  },
  [MessageType.Finland_Travel_SendData]: {
    messageType: MessageType.Finland_Travel_SendData,
    label: NodeLabels.SendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(Country.Finland, BusinessArea.Private, LineOfBusiness.Travel),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(new Finland_Travel_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Finland_Travel_SendDataNode(nodeParams),
  },
  [MessageType.Finland_Travel_GetUserInformation]: {
    messageType: MessageType.Finland_Travel_GetUserInformation,
    label: NodeLabels.GetUserInformation,
    design: () => nodeDesigns.WebchatGetUserInformation,
    filter: {
      include: [
        new Area(Country.Finland, BusinessArea.Private, LineOfBusiness.Travel),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new GetUserInformationLayout(
        new Finland_Travel_GetUserInformationNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Finland_Travel_GetUserInformationNode(nodeParams),
  },
  [MessageType.Finland_InputCountry]: {
    messageType: MessageType.Finland_InputCountry,
    label: NodeLabels.InputCountry,
    design: () => nodeDesigns.Input,
    filter: {
      include: [
        new Area(Country.Finland, BusinessArea.Common, LineOfBusiness.Common),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new InputAutocompleteLayout(new Finland_InputCountryNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Finland_InputCountryNode(nodeParams),
  },
  [MessageType.InputSsn]: {
    messageType: MessageType.InputSsn,
    label: NodeLabels.InputSsn,
    design: () => nodeDesigns.InputSsn,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new InputSsnLayout(new InputSsnNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new InputSsnNode(nodeParams),
  },
  [MessageType.EmployeeAuthentication]: {
    messageType: MessageType.EmployeeAuthentication,
    label: NodeLabels.EmployeeAuthentication,
    design: () => nodeDesigns.EmployeeAuthentication,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new AuthenticationLayout(new EmployeeAuthenticationNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new EmployeeAuthenticationNode(nodeParams),
  },
  [MessageType.LongList]: {
    messageType: MessageType.LongList,
    label: NodeLabels.LongList,
    design: () => nodeDesigns.LongList,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new LongListLayout(new LongListNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new LongListNode(nodeParams),
  },
  [MessageType.WeakAuthentication]: {
    messageType: MessageType.WeakAuthentication,
    label: NodeLabels.WeakAuthentication,
    design: () => nodeDesigns.WeakAuthentication,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new WeakAuthenticationLayout(new WeakAuthenticationNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new WeakAuthenticationNode(nodeParams),
  },
  [MessageType.SilentAuthentication]: {
    messageType: MessageType.SilentAuthentication,
    label: NodeLabels.SilentAuthentication,
    design: () => nodeDesigns.SilentAuthentication,
    filter: {
      include: [
        new Area(Country.Common, BusinessArea.Common, LineOfBusiness.Common),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SilentAuthenticationLayout(new SilentAuthenticationNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new SilentAuthenticationNode(nodeParams),
  },
  [MessageType.SilentEmployeeAuthentication]: {
    messageType: MessageType.SilentEmployeeAuthentication,
    label: NodeLabels.SilentEmployeeAuthentication,
    design: () => nodeDesigns.SilentAuthentication,
    filter: {
      include: [
        new Area(Country.Common, BusinessArea.Common, LineOfBusiness.Common),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SilentAuthenticationLayout(
        new SilentEmployeeAuthenticationNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new SilentEmployeeAuthenticationNode(nodeParams),
  },
  [MessageType.InputPhone]: {
    messageType: MessageType.InputPhone,
    label: NodeLabels.InputPhone,
    design: () => nodeDesigns.InputPhone,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new InputPhoneLayout(new InputPhoneNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new InputPhoneNode(nodeParams),
  },
  [MessageType.Finland_Boat_SendData]: {
    messageType: MessageType.Finland_Boat_SendData,
    label: NodeLabels.SendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(Country.Finland, BusinessArea.Private, LineOfBusiness.Boat),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(new Finland_Boat_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Finland_Boat_SendDataNode(nodeParams),
  },
  [MessageType.Finland_Forest_SendData]: {
    messageType: MessageType.Finland_Forest_SendData,
    label: NodeLabels.SendData,
    design: () => nodeDesigns.SendData,
    filter: {
      include: [
        new Area(Country.Finland, BusinessArea.Private, LineOfBusiness.Forest),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(new Finland_Forest_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Finland_Forest_SendDataNode(nodeParams),
  },
  [MessageType.GetAllClaims]: {
    messageType: MessageType.GetAllClaims,
    label: NodeLabels.GetAllClaims,
    design: () => nodeDesigns.GetUserPolicies,
    filter: {
      include: [
        new Area(Country.Common, BusinessArea.Common, LineOfBusiness.Common),
      ],
      exclude: [
        new Area(Country.Norway, BusinessArea.Private, LineOfBusiness.Travel),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new GetAllClaimsLayout(new GetAllClaimsNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new GetAllClaimsNode(nodeParams),
  },
  [MessageType.Norway_Travel_ShowAllClaims]: {
    messageType: MessageType.Norway_Travel_ShowAllClaims,
    label: NodeLabels.ShowAllClaims,
    design: () => nodeDesigns.WebchatShowPolicies,
    filter: {
      include: [
        new Area(Country.Norway, BusinessArea.Private, LineOfBusiness.Travel),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new ShowAllClaimsLayout(new Norway_Travel_ShowAllClaimsNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Norway_Travel_ShowAllClaimsNode(nodeParams),
  },
  [MessageType.Norway_Travel_GetAllClaims]: {
    messageType: MessageType.Norway_Travel_GetAllClaims,
    label: NodeLabels.GetAllNOTravelClaims,
    design: () => nodeDesigns.GetUserPolicies,
    filter: {
      include: [
        new Area(Country.Norway, BusinessArea.Private, LineOfBusiness.Travel),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new GetAllClaimsLayout(new Norway_Travel_GetAllClaimsNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Norway_Travel_GetAllClaimsNode(nodeParams),
  },
  [MessageType.FraudScore]: {
    messageType: MessageType.FraudScore,
    label: NodeLabels.FraudScore,
    design: () => nodeDesigns.FraudScore,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new FraudScoreLayout(new FraudScoreNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new FraudScoreNode(nodeParams),
  },
  [MessageType.Nordic_Care_RecommendedServices]: {
    messageType: MessageType.Nordic_Care_RecommendedServices,
    label: NodeLabels.Nordic_Care_RecommendedServices,
    design: () => nodeDesigns.RecommendedServices,
    filter: {
      include: [
        new Area(
          Country.Nordic,
          BusinessArea.Common,
          LineOfBusiness.NordicCare
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new RecommendedServicesLayout(
        new Nordic_Care_RecommendedServicesNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Nordic_Care_RecommendedServicesNode(nodeParams),
  },
  [MessageType.SelectCountry]: {
    messageType: MessageType.SelectCountry,
    label: NodeLabels.SelectCountry,
    design: () => nodeDesigns.TellUsClassifierDropdown,
    filter: {
      include: [
        new Area(Country.Common, BusinessArea.Common, LineOfBusiness.Common),
      ],
      exclude: [
        new Area(Country.Denmark, BusinessArea.Common, LineOfBusiness.Common),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new ClassifierLayout(new SelectCountryNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new SelectCountryNode(nodeParams),
  },
  [MessageType.SaveDraft]: {
    messageType: MessageType.SaveDraft,
    label: NodeLabels.SaveDraft,
    design: () => nodeDesigns.SaveDraft,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new SaveDraftLayout(new SaveDraftNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new SaveDraftNode(nodeParams),
  },
  [MessageType.LoadDraft]: {
    messageType: MessageType.LoadDraft,
    label: NodeLabels.LoadDraft,
    design: () => nodeDesigns.LoadDraft,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new LoadDraftLayout(new LoadDraftNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new LoadDraftNode(nodeParams),
  },
  [MessageType.UpdateDraftMetadata]: {
    messageType: MessageType.UpdateDraftMetadata,
    label: NodeLabels.UpdateDraftMetadata,
    design: () => nodeDesigns.UpdateDraftMetadata,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new UpdateDraftMetadataLayout(new UpdateDraftMetadataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new UpdateDraftMetadataNode(nodeParams),
  },
  [MessageType.GetPrefillStorage]: {
    messageType: MessageType.GetPrefillStorage,
    label: NodeLabels.GetPrefillStorage,
    design: () => nodeDesigns.GetPrefillStorage,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new GetPrefillStorageLayout(new GetPrefillStorageNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new GetPrefillStorageNode(nodeParams),
  },
  [MessageType.SetPrefillStorage]: {
    messageType: MessageType.SetPrefillStorage,
    label: NodeLabels.SetPrefillStorage,
    design: () => nodeDesigns.SetPrefillStorage,
    filter: commonFilter,
    createLayout: (nodeParams: NodeParams) =>
      new SetPrefillStorageLayout(new SetPrefillStorageNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new SetPrefillStorageNode(nodeParams),
  },
  [MessageType.Finland_SelectPolarisClassifier]: {
    messageType: MessageType.Finland_SelectPolarisClassifier,
    label: NodeLabels.SelectPolarisClassifier,
    design: () => nodeDesigns.TellUsClassifierDropdown,
    filter: {
      include: [
        new Area(
          Country.Finland,
          BusinessArea.Commercial,
          LineOfBusiness.PersonalInjury
        ),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SelectPolarisClassifierLayout(
        new Finland_SelectPolarisClassifierNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Finland_SelectPolarisClassifierNode(nodeParams),
  },
  [MessageType.StickyNote]: {
    messageType: MessageType.StickyNote,
    label: NodeLabels.StickyNote,
    design: () => nodeDesigns.StickyNote,
    filter: invisibleFilter,
    createLayout: (nodeParams: NodeParams) =>
      new BaseLayout(new BaseNode(nodeParams)),
    getNode: (nodeParams: NodeParams) => new BaseNode(nodeParams),
  },
  [MessageType.Norway_Expatriate_GetPolicies]: {
    messageType: MessageType.Norway_Expatriate_GetPolicies,
    label: NodeLabels.GetCommercialPolicies,
    design: () => nodeDesigns.GetUserPolicies,
    filter: {
      include: [
        new Area(Country.Norway, BusinessArea.Private, LineOfBusiness.Expat),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new GetUserPoliciesLayout(
        new Norway_Expatriate_GetPoliciesNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Norway_Expatriate_GetPoliciesNode(nodeParams),
  },
  [MessageType.Common_Motor_SendData]: {
    messageType: MessageType.Common_Motor_SendData,
    label: NodeLabels.CommonMotorSendData,
    design: () => nodeDesigns.TellusSendData,
    filter: {
      include: [
        new Area(Country.Sweden, BusinessArea.Common, LineOfBusiness.Motor),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new SendDataLayout(new Common_Motor_SendDataNode(nodeParams)),
    getNode: (nodeParams: NodeParams) =>
      new Common_Motor_SendDataNode(nodeParams),
  },
  [MessageType.Sweden_Motor_GetVrfPolicies]: {
    messageType: MessageType.Sweden_Motor_GetVrfPolicies,
    label: NodeLabels.GetVrfPolicies,
    design: () => nodeDesigns.TellusGetUserPolicies,
    filter: {
      include: [
        new Area(Country.Sweden, BusinessArea.Common, LineOfBusiness.Motor),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new GetUserPoliciesLayout(
        new Sweden_Motor_GetVrfPoliciesNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Sweden_Motor_GetVrfPoliciesNode(nodeParams),
  },
  [MessageType.Sweden_Motor_GetCustomerInformationVrf]: {
    messageType: MessageType.Sweden_Motor_GetCustomerInformationVrf,
    label: NodeLabels.GetCustomerInformationVrf,
    design: () => nodeDesigns.TellusGetCustomerInformationVrf,
    filter: {
      include: [
        new Area(Country.Sweden, BusinessArea.Common, LineOfBusiness.Motor),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new GetUserPoliciesLayout(
        new Sweden_Motor_GetCustomerInformationVrfNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Sweden_Motor_GetCustomerInformationVrfNode(nodeParams),
  },
  [MessageType.Sweden_Motor_DuplicateVrfClaimCheck]: {
    messageType: MessageType.Sweden_Motor_DuplicateVrfClaimCheck,
    label: NodeLabels.DuplicateVrfClaimCheck,
    design: () => nodeDesigns.TellusDuplicateVrfClaimCheck,
    filter: {
      include: [
        new Area(Country.Sweden, BusinessArea.Common, LineOfBusiness.Motor),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new GetUserPoliciesLayout(
        new Sweden_Motor_DuplicateVrfClaimCheckNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Sweden_Motor_DuplicateVrfClaimCheckNode(nodeParams),
  },
  [MessageType.Sweden_Motor_GetUserWorkshopRoles]: {
    messageType: MessageType.Sweden_Motor_GetUserWorkshopRoles,
    label: NodeLabels.GetUserWorkshopRoles,
    design: () => nodeDesigns.GetUserWorkshopRoles,
    filter: {
      include: [
        new Area(Country.Sweden, BusinessArea.Common, LineOfBusiness.Motor),
      ],
    },
    createLayout: (nodeParams: NodeParams) =>
      new GetUserWorkshopRolesLayout(
        new Sweden_Motor_GetUserWorkshopRolesNode(nodeParams)
      ),
    getNode: (nodeParams: NodeParams) =>
      new Sweden_Motor_GetUserWorkshopRolesNode(nodeParams),
  },
};

export default nodeVisualConfigurations;
