export enum EdgeTypes {
  SLOPED_EDGE = 'SLOPED_EDGE',
  REGULAR_EDGE = 'REGULAR_EDGE',
}

export enum MultiEdgeProperty {
  /**
   * Indicator whether to use Quadratic curve (for small number of edges)
   * instead of Cubic curve (for larger number of edges)
   */
  UseQuadraticCurve = 'useQuadraticCurve',
  /**
   * Indicator how far from center (a straight line between 2 nodes) is this particular edge.
   * Calculated in steps. If spacing between edges must be X, then offsetCounter
   * is "how many X's from the center is this edge to either direction".
   * Can be negative, 0 or positive. Can be a fraction (three edges will have values -1, 0 and +1.
   * Two edges will have values -0.5 and +0.5)
   */
  OffsetCounter = 'offsetCounter',
  /**
   * A value of -1 or 1 to differentiate edges between 2 nodes pointing in opposite direction.
   * This is necessary because even if 2 edges are
   * "on the same side" from center line, but they are in opposite directions, the curve
   * apex points need to be drawn in opposite directions as well.
   * The same applies to label placement closer to one end of the edge.
   */
  ArbitraryDirection = 'arbitraryDirection',
  /**
   * Alternating indicator whether label should be placed closer to one end or the other end of an edge.
   * Values -1 and 1
   */
  LabelOffsetOscillator = 'labelOffsetOscillator',
}

export interface MultiEdgeProperties {
  [MultiEdgeProperty.UseQuadraticCurve]?: boolean;
  [MultiEdgeProperty.OffsetCounter]?: number;
  [MultiEdgeProperty.ArbitraryDirection]?: number;
  [MultiEdgeProperty.LabelOffsetOscillator]?: number;
}

export const offsetOscillator = {
  odd: -1,
  even: 1,
};
