import ExternalLink from '@/components/BoardPage/NodeProperties/NodePropertiesElements/ExternalLink/ExternalLink';
import { AppRoutes } from '@/routes';
import {
  NodeProperty,
  BaseNodeProperty,
  Nordic_Care_RecommendedServicesNode,
} from '@riga-claims/atlas-models';
import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';
import BaseLayout from './BaseLayout';

class RecommendedServicesLayout extends BaseLayout {
  constructor(node: Nordic_Care_RecommendedServicesNode) {
    super(node);

    const { properties } = node;
    const { message, variableName, answerCanContainPersonalData } =
      BaseNodeProperty;
    const { description, recommendedServiceId, recommendedServices } =
      NodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.inputValidation)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties[message].label,
          placeholder: baseNodeProperties[message].placeholder,
          ...properties[message],
        },
        this.mappedFields
      )
      .addTextAreaField(
        {
          fieldName: description,
          label: 'List description',
          placeholder: 'List description',
          ...properties[description],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: recommendedServiceId,
          label: 'Recommended service Id',
          placeholder: 'Recommended service Id',
          ...properties[recommendedServiceId],
        },
        this.mappedFields
      )

      .addTextAreaField(
        {
          fieldName: recommendedServices,
          label: 'Recommended services',
          placeholder: 'Recommended services',
          ...properties[recommendedServices],
        },
        this.mappedFields
      )
      .addJSXElementField({
        renderElement: () =>
          ExternalLink({
            buttonText: 'Data source composer',
            url: `${AppRoutes.TOOLS}/recommendedServices.html`,
          }),
        fieldName: recommendedServices,
        ...properties[recommendedServices],
      });

    this.contentTab
      .addGroup(nodePropertiesGroups.editNode)
      .addTextField(
        {
          fieldName: variableName,
          label: baseNodeProperties[variableName].label,
          placeholder: baseNodeProperties[variableName].placeholder,
          ...properties[variableName],
        },
        this.mappedFields
      )
      .addCheckboxField(
        {
          fieldName: answerCanContainPersonalData,
          label: baseNodeProperties[answerCanContainPersonalData].label,
          title: baseNodeProperties[answerCanContainPersonalData].title,
          ...properties[answerCanContainPersonalData],
        },
        this.mappedFields
      );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default RecommendedServicesLayout;
