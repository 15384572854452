import {
  BaseNodeProperty,
  Finland_MyPagesAuthenticationNode,
} from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class MyPagesAuthenticationLayout extends BaseLayout {
  constructor(node: Finland_MyPagesAuthenticationNode) {
    super(node);

    const { properties } = node;
    const { variableName, message, validationMessage } = BaseNodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties[message].label,
          placeholder: baseNodeProperties[message].placeholder,
          ...properties[message],
        },
        this.mappedFields
      );

    this.addAccessLogDataGroup({
      hasEnteredData: true,
    });

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.validationTab
      .addGroup(nodePropertiesGroups.authenticationFailed)
      .addTextAreaField(
        {
          fieldName: validationMessage,
          label: nodeProperties.authenticationErrorMessage.label,
          placeholder: nodeProperties.authenticationErrorMessage.placeholder,
          ...properties[validationMessage],
        },
        this.mappedFields
      );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default MyPagesAuthenticationLayout;
