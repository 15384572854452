import BaseLayout from './BaseLayout';
import {
  baseNodeProperties,
  nodeProperties,
  nodePropertiesGroups,
} from '@/nodeVisualConfigurations';
import {
  BaseNodeProperty,
  Finland_InputCityNode,
  NodeProperty,
} from '@riga-claims/atlas-models';
import InputSizeRadioGroup from '@/components/InputSizeRadioGroup/InputSizeRadioGroup';

class FinlandInputCityLayout extends BaseLayout {
  constructor(node: Finland_InputCityNode) {
    super(node);

    const { properties } = node;
    const {
      noResultsFoundMessage,
      allowAnyValue,
      inputPlaceholder,
      inputSize,
    } = NodeProperty;
    const { variableName, message } = BaseNodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties.message.label,
          placeholder: baseNodeProperties.message.placeholder,
          ...properties[message],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: inputPlaceholder,
          label: 'Placeholder (optional)',
          placeholder: 'Add placeholder for input field',
          ...properties[inputPlaceholder],
        },
        this.mappedFields
      )
      .addJSXElementField(
        {
          fieldName: inputSize,
          renderElement: InputSizeRadioGroup,
          ...properties[inputSize],
        },
        this.mappedFields
      );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.validationTab
      .addGroup(nodePropertiesGroups.noResultsFound)
      .addTextAreaField(
        {
          fieldName: noResultsFoundMessage,
          label: 'Error message',
          placeholder:
            'Add error message for customer in case no results are found',
          ...properties[noResultsFoundMessage],
        },
        this.mappedFields
      );
    this.validationTab
      .addGroup(nodePropertiesGroups.inputValidation)
      .addCheckboxField({
        fieldName: allowAnyValue,
        label: nodeProperties[allowAnyValue].label,
      });

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.addAccessLogDataGroup({
      hasEnteredData: true,
    });
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default FinlandInputCityLayout;
