import { Colors } from '@/constants';
import { G6Styles } from '@/G6';
import { NodeShape } from '../nodeShape';

const createG6JsxErrorIcon = (): string => `
<circle
  name="${NodeShape.ERROR_ICON}"
  style={{
    ${G6Styles.R}: ${28},
    ${G6Styles.MARGIN_TOP}: ${-152},
    ${G6Styles.MARGIN_LEFT}: ${0},
    ${G6Styles.FILL}: '${Colors.RED_100}',
    ${G6Styles.CURSOR}: pointer,
  }}
  draggable="true"
  visible="false"
  >
  <text
    name="${NodeShape.ERROR_ICON}"
    style={{
      ${G6Styles.TEXT_BASELINE}: 'bottom',
      ${G6Styles.FILL}: 'white',
      ${G6Styles.FONT_SIZE}: 28,
      ${G6Styles.MARGIN_LEFT}: ${-4},
      ${G6Styles.CURSOR}: pointer,
    }}
    draggable="true"
    visible="false"
  >!</text>
</circle>
`;

export default createG6JsxErrorIcon;
