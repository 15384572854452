import {
  BaseNodeProperty,
  FraudScoreNode,
  NodeProperty,
} from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';
import BaseLayout from './BaseLayout';

class FraudScoreLayout extends BaseLayout {
  constructor(node: FraudScoreNode) {
    super(node);

    const { properties } = node;
    const { variableName } = BaseNodeProperty;
    const { country, lineOfBusiness, businessArea, product } = NodeProperty;

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.propertiesTab
      .addGroup()
      .addTextField(
        {
          fieldName: country,
          label: 'Country',
          placeholder: node.properties[country].possibleValues?.join(', '),
          ...node.properties[country],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: lineOfBusiness,
          label: 'Line of business',
          placeholder: node.properties[lineOfBusiness].possibleValues?.join(', '),
          ...node.properties[lineOfBusiness],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: businessArea,
          label: 'Business area',
          placeholder: node.properties[businessArea].possibleValues?.join(', '),
          ...node.properties[businessArea],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: product,
          label: 'Product',
          placeholder: node.properties[product].possibleValues?.join(', '),
          ...node.properties[product],
        },
        this.mappedFields
      );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default FraudScoreLayout;
