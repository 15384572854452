import ExternalLink from '@/components/BoardPage/NodeProperties/NodePropertiesElements/ExternalLink/ExternalLink';
import { AppRoutes } from '@/routes';
import {
  BaseNodeProperty,
  LongListNode,
  NodeProperty,
} from '@riga-claims/atlas-models';
import {
  baseNodeProperties,
  nodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class LongListLayout extends BaseLayout {
  constructor(node: LongListNode) {
    super(node);

    const { properties } = node;

    const { message, variableName, comment } = BaseNodeProperty;

    const {
      title,
      dataSource,
      maxItemsToSelect,
      maxItemsToSelectErrorMessage,
      openButtonText,
    } = NodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties[message].label,
          placeholder: baseNodeProperties[message].placeholder,
          ...properties[message],
        },
        this.mappedFields
      )
      .addTextAreaField(
        {
          fieldName: title,
          label: nodeProperties.longListTitle.label,
          placeholder: nodeProperties.longListTitle.placeholder,
          ...properties[title],
        },
        this.mappedFields
      )
      .addTextAreaField(
        {
          fieldName: dataSource,
          label: nodeProperties[dataSource].label,
          placeholder: nodeProperties[dataSource].placeholder,
          ...properties[dataSource],
        },
        this.mappedFields
      )
      .addJSXElementField({
        renderElement: () =>
          ExternalLink({
            buttonText: 'Data source composer',
            url: `${AppRoutes.TOOLS}/longLists.html`,
          }),
        fieldName: dataSource,
        ...properties[dataSource],
      })
      .addTextField(
        {
          fieldName: openButtonText,
          label: nodeProperties[openButtonText].label,
          placeholder: nodeProperties[openButtonText].placeholder,
          ...properties[openButtonText],
        },
        this.mappedFields
      );

    this.contentTab
      .addGroup(nodePropertiesGroups.editNode)
      .addTextField(
        {
          fieldName: variableName,
          label: baseNodeProperties[variableName].label,
          placeholder: baseNodeProperties[variableName].placeholder,
          ...properties[variableName],
        },
        this.mappedFields
      )
      .addTextAreaField(
        {
          fieldName: comment,
          label: baseNodeProperties[comment].label,
          placeholder: baseNodeProperties[comment].placeholder,
          ...properties[comment],
        },
        this.mappedFields
      );

    this.validationTab
      .addGroup()
      .addTextField(
        {
          fieldName: maxItemsToSelect,
          label: nodeProperties[maxItemsToSelect].label,
          placeholder: nodeProperties[maxItemsToSelect].placeholder,
          ...properties[maxItemsToSelect],
        },
        this.mappedFields
      )
      .addTextAreaField(
        {
          fieldName: maxItemsToSelectErrorMessage,
          label: nodeProperties[maxItemsToSelectErrorMessage].label,
          placeholder: nodeProperties[maxItemsToSelectErrorMessage].placeholder,
          ...properties[maxItemsToSelectErrorMessage],
        },
        this.mappedFields
      );

    this.addSummaryOverviewGroup();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default LongListLayout;
