import { BaseNodeProperty, CarouselNode } from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class CarouselLayout extends BaseLayout {
  constructor(node: CarouselNode) {
    super(node);

    const { properties } = node;
    const { message, variableName } = BaseNodeProperty;

    // TODO there should be a separate prop for the embedded file name,
    // "message" should not be reused in this way
    this.contentTab.addGroup().addTextField(
      {
        fieldName: message,
        label: nodeProperties.embeddedFile.label,
        placeholder: nodeProperties.embeddedFile.placeholder,
        ...properties[message],
      },
      this.mappedFields
    );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.addCommentField();
    this.addAccessLogDataGroup({
      hasEnteredData: true,
    });
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default CarouselLayout;
