import { EditorConfiguration, EditorConfigurationKeys } from './types';

const stestConfig: EditorConfiguration = {
  [EditorConfigurationKeys.APP_INSIGHTS_INSTRUMENTATION_KEY]:
    '237f0597-d3d8-436e-87eb-606f89b2a43b',
  [EditorConfigurationKeys.LAUNCH_DARKLY_CLIENT_SIDE_KEY]:
    '5c6669cedb8ac42df4f1f346',
  [EditorConfigurationKeys.LAUNCH_DARKLY_USER_KEY]:
    '7D3F72F1-0C28-4E3B-82AE-5F760A5859C7',
  [EditorConfigurationKeys.LAUNCH_DARKLY_APPLICATION_NAME]: 'Atlas Editor UI',
  [EditorConfigurationKeys.LAUNCH_DARKLY_ENVIRONMENT]: 'STEST',
};

export default stestConfig;
