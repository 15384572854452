export enum EditorConfigurationKeys {
  APP_INSIGHTS_INSTRUMENTATION_KEY = 'appInsightsInstrumentationKey',
  LAUNCH_DARKLY_CLIENT_SIDE_KEY = 'launchDarklyClientSideKey',
  LAUNCH_DARKLY_USER_KEY = 'launchDarklyUserKey',
  LAUNCH_DARKLY_APPLICATION_NAME = 'launchDarklyApplicationName',
  LAUNCH_DARKLY_ENVIRONMENT = 'launchDarklyEnvironment',
}

export interface EditorConfiguration {
  [EditorConfigurationKeys.APP_INSIGHTS_INSTRUMENTATION_KEY]: string;
  [EditorConfigurationKeys.LAUNCH_DARKLY_CLIENT_SIDE_KEY]: string;
  [EditorConfigurationKeys.LAUNCH_DARKLY_USER_KEY]: string;
  [EditorConfigurationKeys.LAUNCH_DARKLY_APPLICATION_NAME]: string;
  [EditorConfigurationKeys.LAUNCH_DARKLY_ENVIRONMENT]: string;
}
