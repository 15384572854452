import {
  NodeProperty,
  BaseNodeProperty,
  Finland_WorkersCompensation_GetExposuresNode,
} from '@riga-claims/atlas-models';
import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';
import BaseLayout from './BaseLayout';

class FinlandWorkersCompGetExposuresLayout extends BaseLayout {
  constructor(node: Finland_WorkersCompensation_GetExposuresNode) {
    super(node);

    const { properties } = node;
    const { variableName } = BaseNodeProperty;
    const { policy, exposureId } = NodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.inputValidation)
      .addTextField(
        {
          fieldName: policy,
          label: 'Chosen Policy',
          placeholder: 'Chosen policy object from policy search',
          ...node.properties[policy],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: exposureId,
          label: 'Exposure object id',
          placeholder: 'Exposure object id',
          ...node.properties[exposureId],
        },
        this.mappedFields
      );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.addAccessLogDataGroup({
      hasEnteredData: false,
    });
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default FinlandWorkersCompGetExposuresLayout;
