import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AuthorizedUser } from '@/api/types';

export interface AuthState {
  user: AuthorizedUser | null;
}

export const initialAuthState: AuthState = {
  user: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    setUser: (state, action: PayloadAction<AuthorizedUser>) => {
      state.user = action.payload;
    },
    resetAuthState: () => initialAuthState,
  },
});

export const { setUser, resetAuthState } = authSlice.actions;

export default authSlice.reducer;
