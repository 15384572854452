import {
  BaseNodeProperty,
  NodeProperty,
  Finland_Animal_ShowPoliciesNode,
} from '@riga-claims/atlas-models';
import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';
import BaseLayout from './BaseLayout';

class FinlandAnimalShowPoliciesLayout extends BaseLayout {
  constructor(node: Finland_Animal_ShowPoliciesNode) {
    super(node);

    const { properties } = node;
    const { policies } = NodeProperty;
    const { variableName, message } = BaseNodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties.message.label,
          placeholder: baseNodeProperties.message.placeholder,
          ...properties[message],
        },
        this.mappedFields
      );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.propertiesTab.addGroup().addTextField(
      {
        fieldName: policies,
        label: 'Policies data',
        placeholder: '',
        ...properties[policies],
      },
      this.mappedFields
    );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.addAccessLogDataGroup({
      hasEnteredData: true,
    });
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default FinlandAnimalShowPoliciesLayout;
