import {
  BaseNodeProperty,
  Norway_Travel_GetPoliciesNode,
  Norway_Animal_GetPoliciesNode,
  Norway_Property_GetPoliciesNode,
  Norway_PersonalInjury_Commercial_GetPoliciesNode,
  Finland_Motor_GetPoliciesNode,
  Finland_WorkersCompensation_GetPoliciesNode,
  Sweden_Property_GetPoliciesNode,
  NodeProperty,
  MessageType,
  Norway_Motor_GetPoliciesNode,
  Norway_Expatriate_GetPoliciesNode,
  Sweden_Motor_GetVrfPoliciesNode,
  Sweden_Motor_GetCustomerInformationVrfNode,
  Sweden_Motor_DuplicateVrfClaimCheckNode,
} from '@riga-claims/atlas-models';
import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';
import BaseLayout from './BaseLayout';

class GetUserPoliciesLayout extends BaseLayout {
  constructor(
    node:
      | Norway_Travel_GetPoliciesNode
      | Norway_PersonalInjury_Commercial_GetPoliciesNode
      | Norway_Animal_GetPoliciesNode
      | Finland_Motor_GetPoliciesNode
      | Finland_WorkersCompensation_GetPoliciesNode
      | Sweden_Property_GetPoliciesNode
      | Norway_Property_GetPoliciesNode
      | Norway_Motor_GetPoliciesNode
      | Norway_Expatriate_GetPoliciesNode
      | Sweden_Motor_GetVrfPoliciesNode
      | Sweden_Motor_GetCustomerInformationVrfNode
      | Sweden_Motor_DuplicateVrfClaimCheckNode
  ) {
    super(node);

    const { properties } = node;
    const { variableName, answerCanContainPersonalData } = BaseNodeProperty;
    const {
      ssn,
      customerNumber,
      policyValidDaysAgo,
      policyNumber,
      incidentDate,
      resultProperties,
      animalType,
      claimType,
      vehicleRegistrationNumber,
      customerSsn,
      orderedProductParts,
      coverageTypeNumber,
      concept,
      onlyWithSpecialGroups,
    } = NodeProperty;

    const identificationGroup = this.contentTab.addGroup(
      nodePropertiesGroups.identification
    );

    switch (node.messageType) {
      case MessageType.Norway_Travel_GetPolicies:
        identificationGroup
          .addTextField(
            {
              fieldName: customerNumber,
              label: 'Customer number',
              placeholder: 'Customer number here',
              ...(node as Norway_Travel_GetPoliciesNode).properties[
                customerNumber
              ],
            },
            this.mappedFields
          )
          .addTextField(
            {
              fieldName: policyNumber,
              label: 'Policy number',
              placeholder: 'Policy number here',
              ...(node as Norway_Travel_GetPoliciesNode).properties[
                policyNumber
              ],
            },
            this.mappedFields
          );

        this.validationTab.addGroup('Policy validation').addTextField(
          {
            fieldName: policyValidDaysAgo,
            label: 'Policy valid days ago',
            placeholder: '30',
            ...(node as Norway_Travel_GetPoliciesNode).properties[
              policyValidDaysAgo
            ],
          },
          this.mappedFields
        );
        break;

      case MessageType.Norway_PersonalInjury_Commercial_GetPolicies:
        identificationGroup
          .addTextField(
            {
              fieldName: policyNumber,
              label: 'Policy number',
              placeholder: 'Policy number here',
              ...(node as Norway_PersonalInjury_Commercial_GetPoliciesNode)
                .properties[policyNumber],
            },
            this.mappedFields
          )
          .addTextField(
            {
              fieldName: incidentDate,
              label: 'Incident date',
              placeholder: 'Incident date here',
              ...(node as Norway_PersonalInjury_Commercial_GetPoliciesNode)
                .properties[incidentDate],
            },
            this.mappedFields
          )
          .addTextField(
            {
              fieldName: resultProperties,
              label: 'Result properties',
              placeholder: 'Choose result properties',
              ...(node as Norway_PersonalInjury_Commercial_GetPoliciesNode)
                .properties[resultProperties],
            },
            this.mappedFields
          );
        break;

      case MessageType.Norway_Animal_GetPolicies:
        identificationGroup
          .addTextField(
            {
              fieldName: customerNumber,
              label: 'Customer number',
              placeholder: '[customerNumber]',
              ...(node as Norway_Animal_GetPoliciesNode).properties[
                customerNumber
              ],
            },
            this.mappedFields
          )
          .addTextField(
            {
              fieldName: policyNumber,
              label: 'Policy number',
              placeholder: '[policyNumber]',
              ...(node as Norway_Animal_GetPoliciesNode).properties[
                policyNumber
              ],
            },
            this.mappedFields
          )
          .addTextField(
            {
              fieldName: animalType,
              label: 'Animal type',
              placeholder: '[animalType]',
              ...(node as Norway_Animal_GetPoliciesNode).properties[animalType],
            },
            this.mappedFields
          );
        break;

      case MessageType.Norway_Property_GetPolicies:
        identificationGroup
          .addTextField(
            {
              fieldName: customerNumber,
              label: 'Customer number',
              placeholder: '[CustomerNumber]',
              ...(node as Norway_Property_GetPoliciesNode).properties[
                customerNumber
              ],
            },
            this.mappedFields
          )
          .addTextField(
            {
              fieldName: claimType,
              label: 'Claim type',
              placeholder: '[ClaimType]',
              ...(node as Norway_Property_GetPoliciesNode).properties[
                claimType
              ],
            },
            this.mappedFields
          );
        break;

      case MessageType.Finland_Motor_GetPolicies:
        identificationGroup
          .addTextField(
            {
              fieldName: ssn,
              label: 'SSN',
              placeholder: 'SSN here',
              ...(node as Finland_Motor_GetPoliciesNode).properties[ssn],
            },
            this.mappedFields
          )
          .addTextField(
            {
              fieldName: incidentDate,
              label: 'Incident date',
              placeholder: 'Incident date here',
              ...(node as Finland_Motor_GetPoliciesNode).properties[
                incidentDate
              ],
            },
            this.mappedFields
          );
        break;

      case MessageType.Finland_WorkersCompensation_GetPolicies:
        identificationGroup
          .addTextField(
            {
              fieldName: customerNumber,
              label: 'Customer number',
              placeholder: 'Customer number here',
              ...(node as Finland_WorkersCompensation_GetPoliciesNode)
                .properties[customerNumber],
            },
            this.mappedFields
          )
          .addTextField(
            {
              fieldName: policyNumber,
              label: 'Policy number',
              placeholder: 'Policy number here',
              ...(node as Finland_WorkersCompensation_GetPoliciesNode)
                .properties[policyNumber],
            },
            this.mappedFields
          )
          .addTextField(
            {
              fieldName: incidentDate,
              label: 'Incident date',
              placeholder: 'Incident date here',
              ...(node as Finland_WorkersCompensation_GetPoliciesNode)
                .properties[incidentDate],
            },
            this.mappedFields
          )
          .addCheckboxField(
            {
              fieldName: onlyWithSpecialGroups,
              label: 'Only policies with special groups',
              title: 'Get all or only policies with special groups',
              ...(node as Finland_WorkersCompensation_GetPoliciesNode)
                .properties[onlyWithSpecialGroups],
            },
            this.mappedFields
          );
        break;
      case MessageType.Norway_Motor_GetPolicies:
        identificationGroup
          .addTextField(
            {
              fieldName: incidentDate,
              label: 'Incident date',
              placeholder: 'Incident date here',
              ...(node as Norway_Motor_GetPoliciesNode).properties[
                incidentDate
              ],
            },
            this.mappedFields
          )
          .addTextField(
            {
              fieldName: policyNumber,
              label: 'Policy number',
              placeholder: 'Policy number here',
              ...(node as Norway_Motor_GetPoliciesNode).properties[
                policyNumber
              ],
            },
            this.mappedFields
          )
          .addTextField(
            {
              fieldName: vehicleRegistrationNumber,
              label: 'Vehicle registration number',
              placeholder: 'Vehicle registration number here',
              ...(node as Norway_Motor_GetPoliciesNode).properties[
                vehicleRegistrationNumber
              ],
            },
            this.mappedFields
          );
        break;

      case MessageType.Sweden_Property_GetPolicies:
        identificationGroup
          .addTextField(
            {
              fieldName: customerSsn,
              label: 'Customer SSN',
              placeholder: 'Customer SSN here',
              ...(node as Sweden_Property_GetPoliciesNode).properties[
                customerSsn
              ],
            },
            this.mappedFields
          )
          .addTextField(
            {
              fieldName: incidentDate,
              label: 'Incident date',
              placeholder: 'Incident date here',
              ...(node as Sweden_Property_GetPoliciesNode).properties[
                incidentDate
              ],
            },
            this.mappedFields
          )
          .addTextField(
            {
              fieldName: orderedProductParts,
              label: 'Ordered product parts',
              placeholder: 'Ordered product parts here',
              ...(node as Sweden_Property_GetPoliciesNode).properties[
                orderedProductParts
              ],
            },
            this.mappedFields
          )
          .addTextField(
            {
              fieldName: coverageTypeNumber,
              label: 'Coverage type number',
              placeholder: 'Coverage type number here',
              ...(node as Sweden_Property_GetPoliciesNode).properties[
                coverageTypeNumber
              ],
            },
            this.mappedFields
          );
        break;
      case MessageType.Norway_Expatriate_GetPolicies:
        identificationGroup.addTextField(
          {
            fieldName: policyNumber,
            label: 'Policy number',
            placeholder: 'Policy number here',
            ...(node as Norway_Expatriate_GetPoliciesNode).properties[
              policyNumber
            ],
          },
          this.mappedFields
        );
        break;

      case MessageType.Sweden_Motor_GetVrfPolicies:
        identificationGroup
          .addTextField(
            {
              fieldName: incidentDate,
              label: 'Incident date',
              placeholder: 'Incident date',
              ...(node as Sweden_Motor_GetVrfPoliciesNode).properties[
                incidentDate
              ],
            },
            this.mappedFields
          )
          .addTextField(
            {
              fieldName: policyNumber,
              label: 'Policy number',
              placeholder: 'Policy number',
              ...(node as Sweden_Motor_GetVrfPoliciesNode).properties[
                policyNumber
              ],
            },
            this.mappedFields
          )
          .addTextField(
            {
              fieldName: vehicleRegistrationNumber,
              label: 'Vehicle registration number',
              placeholder: 'Vehicle registration number',
              ...(node as Sweden_Motor_GetVrfPoliciesNode).properties[
                vehicleRegistrationNumber
              ],
            },
            this.mappedFields
          );
        break;

      case MessageType.Sweden_Motor_GetCustomerInformationVrf:
        identificationGroup.addTextField(
          {
            fieldName: concept,
            label: 'Concept',
            placeholder: 'V, R, P or empty',
            ...(node as Sweden_Motor_GetCustomerInformationVrfNode).properties[
              concept
            ],
          },
          this.mappedFields
        );
        break;

      case MessageType.Sweden_Motor_DuplicateVrfClaimCheck:
        identificationGroup
          .addTextField(
            {
              fieldName: vehicleRegistrationNumber,
              label: 'Vehicle registration number',
              placeholder: 'Vehicle registration number',
              ...(node as Sweden_Motor_DuplicateVrfClaimCheckNode).properties[
                vehicleRegistrationNumber
              ],
            },
            this.mappedFields
          )
          .addTextField(
            {
              fieldName: incidentDate,
              label: 'Incident date',
              placeholder: 'Incident date',
              ...(node as Sweden_Motor_DuplicateVrfClaimCheckNode).properties[
                incidentDate
              ],
            },
            this.mappedFields
          );
        break;
      default:
        break;
    }

    this.contentTab
      .addGroup(nodePropertiesGroups.editNode)
      .addTextField(
        {
          fieldName: variableName,
          label: baseNodeProperties[variableName].label,
          placeholder: baseNodeProperties[variableName].placeholder,
          ...properties[variableName],
        },
        this.mappedFields
      )
      .addCheckboxField(
        {
          fieldName: answerCanContainPersonalData,
          label: baseNodeProperties[answerCanContainPersonalData].label,
          title: baseNodeProperties[answerCanContainPersonalData].title,
          ...properties[answerCanContainPersonalData],
        },
        this.mappedFields
      );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default GetUserPoliciesLayout;
