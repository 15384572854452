import { INode } from '@antv/g6';
import { MessageType } from '@riga-claims/atlas-models';

export const MAX_SIBLING_EDGES_COUNT = 20;

export const MessageTypesWithMultipleOutgoingEdgesEnabled = [
  MessageType.InputChoiceFromCarousel,
  MessageType.InputChoice,
  MessageType.InputMultipleChoice,
  MessageType.InputText,
  MessageType.Splitter,
  MessageType.Carousel,
  MessageType.ListFromCarousel,
];

export interface ShouldCreateNewEdgeParams {
  sourceNode: INode;
  targetNode: INode;
}

const shouldCreateNewEdge = ({
  sourceNode,
  targetNode,
}: ShouldCreateNewEdgeParams) => {
  const clickedNodeInEdges = targetNode.getInEdges();

  const allowMultipleEdges = MessageTypesWithMultipleOutgoingEdgesEnabled.some(
    (mt) => mt === sourceNode.getModel().messageType
  );

  const isLoop = sourceNode.getID() === targetNode.getID();
  const isExistingEdge = clickedNodeInEdges.some(
    (edge) => edge.getModel().source === sourceNode.getID()
  );

  const numberOfSiblingEdges = targetNode
    .getEdges()
    .filter(
      (edge) =>
        edge.getModel().source === sourceNode.getID() ||
        edge.getModel().target === sourceNode.getID()
    ).length;

  if (
    !isLoop &&
    (!isExistingEdge || allowMultipleEdges) &&
    numberOfSiblingEdges < MAX_SIBLING_EDGES_COUNT
  ) {
    return true;
  }

  return false;
};

export default shouldCreateNewEdge;
