import { ButtonPrimary } from '@ids-react/button';

import classes from './ErrorPage.module.scss';

export const firstLine = 'Uh oh!';
export const secondLine = 'Something went wrong at our end.';
export const thirdLine =
  "Don't worry, it's not you - it's us. Sorry about that.";
export const buttonLabel = 'Refresh';

const ErrorPage: React.FunctionComponent = () => {
  const reloadHandler = () => {
    localStorage.clear();
    sessionStorage.clear();
    location.reload();
  };

  return (
    <div className={classes.ErrorPage}>
      <img src="/errorPage.png" alt={secondLine} />
      <div className={classes.Message}>
        <h1 className="if">{firstLine}</h1>
        <h2 className="if">{secondLine}</h2>
        <h2 className="if">{thirdLine}</h2>
        <ButtonPrimary onClick={reloadHandler}>{buttonLabel}</ButtonPrimary>
      </div>
    </div>
  );
};

export default ErrorPage;
