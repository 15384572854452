import {
  BaseNodeProperty,
  NodeProperty,
  LoadDraftNode,
} from '@riga-claims/atlas-models';
import {
  baseNodeProperties,
  nodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class LoadDraftLayout extends BaseLayout {
  constructor(node: LoadDraftNode) {
    super(node);

    const { properties } = node;
    const { variableName } = BaseNodeProperty;
    const { draftId, originalConversationId, firstNodeId, lastNodeId } =
      NodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.editNode)
      .addTextField(
        {
          fieldName: variableName,
          label: baseNodeProperties[variableName].label,
          placeholder: baseNodeProperties[variableName].placeholder,
          ...properties[variableName],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: draftId,
          label: nodeProperties[draftId].label,
          placeholder: nodeProperties[draftId].placeholder,
          ...properties[draftId],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: originalConversationId,
          label: nodeProperties[originalConversationId].label,
          placeholder: nodeProperties[originalConversationId].placeholder,
          ...properties[originalConversationId],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: firstNodeId,
          label: nodeProperties[firstNodeId].label,
          placeholder: nodeProperties[firstNodeId].placeholder,
          ...properties[firstNodeId],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: lastNodeId,
          label: nodeProperties[lastNodeId].label,
          placeholder: nodeProperties[lastNodeId].placeholder,
          ...properties[lastNodeId],
        },
        this.mappedFields
      );

    this.addCommentField();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default LoadDraftLayout;
