import {
  NodeProperty,
  BaseNodeProperty,
  Finland_WorkersCompensation_GetOccupationalClassesNode,
} from '@riga-claims/atlas-models';
import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';
import BaseLayout from './BaseLayout';

class FinlandWorkersCompGetOccupationalClassesLayout extends BaseLayout {
  constructor(node: Finland_WorkersCompensation_GetOccupationalClassesNode) {
    super(node);

    const { properties } = node;
    const { variableName, answerCanContainPersonalData } = BaseNodeProperty;
    const { chosenPolicy, chosenExposureObjectId, chosenExposure } =
      NodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.inputValidation)
      .addTextField(
        {
          fieldName: chosenPolicy,
          label: 'Chosen Policy',
          placeholder: 'Chosen policy object from policy search',
          ...node.properties[chosenPolicy],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: chosenExposureObjectId,
          label: 'Chosen exposure object id',
          placeholder: 'Chosen exposure object id',
          ...node.properties[chosenExposureObjectId],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: chosenExposure,
          label: 'Chosen exposure',
          placeholder: 'Chosen exposure',
          ...node.properties[chosenExposure],
        },
        this.mappedFields
      );

    this.contentTab
      .addGroup(nodePropertiesGroups.editNode)
      .addTextField(
        {
          fieldName: variableName,
          label: baseNodeProperties[variableName].label,
          placeholder: baseNodeProperties[variableName].placeholder,
          ...properties[variableName],
        },
        this.mappedFields
      )
      .addCheckboxField(
        {
          fieldName: answerCanContainPersonalData,
          label: baseNodeProperties[answerCanContainPersonalData].label,
          title: baseNodeProperties[answerCanContainPersonalData].title,
          ...properties[answerCanContainPersonalData],
        },
        this.mappedFields
      );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default FinlandWorkersCompGetOccupationalClassesLayout;
