export const APP_NAME = 'Atlas Editor';

export enum Hostname {
  LOCALHOST = 'localhost',
}

export enum Environments {
  DEV = 'dev',
  ITEST = 'itest',
  STEST = 'stest',
  ATEST = 'atest',
  PROD = 'prod',
}

export const StatisticsPageUrls = {
  [Environments.DEV]:
    'https://claims-chat-bot-web-itest.azurewebsites.net/Statistics',
  [Environments.ITEST]:
    'https://claims-chat-bot-web-itest.azurewebsites.net/Statistics',
  [Environments.STEST]:
    'https://claims-chat-bot-web-stest.azurewebsites.net/Statistics',
  [Environments.ATEST]:
    'https://claims-chat-bot-web-atest.azurewebsites.net/Statistics',
  [Environments.PROD]:
    'https://claims-chat-bot-web.azurewebsites.net/Statistics',
};

export const ChatRootUrls = {
  [Environments.DEV]: 'https://itest.atlas.ifext.biz',
  [Environments.ITEST]: 'https://itest.atlas.ifext.biz',
  [Environments.STEST]: 'https://stest.atlas.ifext.biz',
  [Environments.ATEST]: 'https://atest.atlas.ifext.biz',
  [Environments.PROD]: '',
};

export const TellusRootUrls = {
  [Environments.DEV]: 'https://itest.tellus.ifext.biz',
  [Environments.ITEST]: 'https://itest.tellus.ifext.biz',
  [Environments.STEST]: 'https://stest.tellus.ifext.biz',
  [Environments.ATEST]: 'https://atest.tellus.ifext.biz',
  [Environments.PROD]: '',
};

export const SVG_SCHEMA = 'http://www.w3.org/2000/svg';

export enum KeyCodes {
  Enter = 'Enter',
  Backspace = 'Backspace',
  Spacebar = ' ',
  Escape = 'Escape',
  Tab = 'Tab',
  Delete = 'Delete',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
}

export enum MouseButtons {
  LEFT = 0,
  MIDDLE = 1,
  RIGHT = 2,
  BACK = 3,
  FORWARD = 4,
}

export enum HTMLCodes {
  CHEVRON_LEFT = '&#60;',
  CHEVRON_RIGHT = '&#62;',
}

export const ELLIPSIS = '...';

// IF DS color codes
export enum Colors {
  BLACK = '#000000',
  WHITE = '#FFFFFF',
  BROWN_100 = '#6E625E',
  BROWN_200 = '#331E11',
  BEIGE_100 = '#FAF9F7',
  BEIGE_300 = '#F1ECE8',
  BLUE_100 = '#0054f0',
  ORANGE_100 = '#F09C00',
  RED_100 = '#BB320C',
}

export const BOARD_PAGE_RIGHT_SIDE_PANEL_WIDTH = 400;

export const ON_CHANGE_UPDATE_DELAY = 500;

export enum TestClasses {
  LOADER = 'LOADER',
}
