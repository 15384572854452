import { FunctionComponent } from 'react';
import { Graph } from '@antv/g6';

import { NodeParams } from '@riga-claims/atlas-models';
import { SortableList } from '@riga-claims/storybook';

import { JSXElementRenderProps } from '@/nodeVisualConfigurations';
import type { DebouncedUpdateFlowGraphStore } from '@/hooks';

const updateValuesForNode = ({
  graph,
  values,
  selectedNodeParams,
  debouncedUpdateFlowGraphStore,
}: {
  graph: Graph;
  values: string[];
  selectedNodeParams: NodeParams;
  debouncedUpdateFlowGraphStore: DebouncedUpdateFlowGraphStore;
}) => {
  const { properties: previousProperties, id } = selectedNodeParams;

  graph.updateItem(id, {
    properties: {
      ...previousProperties,
      item: { values: values },
    },
  });
  debouncedUpdateFlowGraphStore({ updateInstantly: true });
};

const MultipleChoiceItemGroup: FunctionComponent<JSXElementRenderProps> = ({
  graph,
  selectedNodeParams,
  debouncedUpdateFlowGraphStore,
}: JSXElementRenderProps) => {
  const values: string[] = selectedNodeParams.properties.item?.values || [];

  const onItemAdd = () => {
    const updatedValues: string[] = [...values, ''];
    updateValuesForNode({
      graph,
      selectedNodeParams,
      values: updatedValues,
      debouncedUpdateFlowGraphStore,
    });
  };

  const onItemRemove = (_value: string, index: number) => {
    const updatedValues = [...values];
    updatedValues.splice(index, 1);
    updateValuesForNode({
      graph,
      selectedNodeParams,
      values: updatedValues,
      debouncedUpdateFlowGraphStore,
    });
  };

  const onItemSortUp = (_value: string, index: number) => {
    const updatedValues = [...values];
    const toIndex = index - 1;
    const element = values[index];
    updatedValues.splice(index, 1);
    updatedValues.splice(toIndex, 0, element);

    updateValuesForNode({
      graph,
      selectedNodeParams,
      values: updatedValues,
      debouncedUpdateFlowGraphStore,
    });
  };

  const onItemSortDown = (_item: string, index: number) => {
    const updatedValues = [...values];
    const toIndex = index + 1;
    const element = values[index];
    updatedValues.splice(index, 1);
    updatedValues.splice(toIndex, 0, element);

    updateValuesForNode({
      graph,
      selectedNodeParams,
      values: updatedValues,
      debouncedUpdateFlowGraphStore,
    });
  };

  const onItemBlur = (value: string, index: number, newValue: string) => {
    if (value !== newValue) {
      const updatedValues = [...values];
      updatedValues[index] = newValue;

      updateValuesForNode({
        graph,
        selectedNodeParams,
        values: updatedValues,
        debouncedUpdateFlowGraphStore,
      });
    }
  };

  return (
    <>
      <SortableList
        values={values}
        onItemAdd={onItemAdd}
        onItemRemove={onItemRemove}
        onItemSortUp={onItemSortUp}
        onItemSortDown={onItemSortDown}
        onItemBlur={onItemBlur}
      />
    </>
  );
};

export default MultipleChoiceItemGroup;
