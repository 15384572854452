import { Graph } from '@antv/g6';

import { G6Keys, G6NodeStates } from '@/G6';
import { NodeShape } from '../nodeShape';
import { NodeStatesWithStyle } from './nodeStyles';

export const ALWAYS_VISIBLE_NODE_ELEMENTS = [
  NodeShape.BASE,
  NodeShape.MARKER,
  NodeShape.STATE_MASK,
];

export interface GetNodeStateVisualModifiersParams {
  nodeStates: G6NodeStates[];
  graph: Graph;
}

const getNodeStateVisualModifiers = ({
  nodeStates,
  graph,
}: GetNodeStateVisualModifiersParams): {
  nodeStateToVisualize: NodeStatesWithStyle;
  elementsToShow: NodeShape[];
} => {
  let nodeStateToVisualize = G6NodeStates.DEFAULT;

  const elementsToShow: NodeShape[] = [...ALWAYS_VISIBLE_NODE_ELEMENTS];

  // Node change on user interactions
  if (nodeStates.includes(G6NodeStates.MULTIPLE_SELECTED)) {
    nodeStateToVisualize = G6NodeStates.MULTIPLE_SELECTED;
  } else if (nodeStates.includes(G6NodeStates.SELECTED)) {
    nodeStateToVisualize = G6NodeStates.SELECTED;
    elementsToShow.push(NodeShape.ANCHOR_POINT);
  } else if (nodeStates.includes(G6NodeStates.HOVER)) {
    if (graph.get(G6Keys.IS_DRAWING_EDGE)) {
      nodeStateToVisualize = G6NodeStates.HOVER_WITH_EDGE;
    } else {
      nodeStateToVisualize = G6NodeStates.HOVER;
      elementsToShow.push(NodeShape.ANCHOR_POINT);
    }
  } else if (nodeStates.includes(G6NodeStates.MARKED_HIGHLIGHTED)) {
    nodeStateToVisualize = G6NodeStates.MARKED_HIGHLIGHTED;
  } else if (nodeStates.includes(G6NodeStates.MARKED)) {
    nodeStateToVisualize = G6NodeStates.MARKED;
  } else if (nodeStates.includes(G6NodeStates.ERROR)) {
    nodeStateToVisualize = G6NodeStates.ERROR;
    elementsToShow.push(NodeShape.ERROR_ICON);
  }

  // Node change on multiple zoom levels
  if (nodeStates.includes(G6NodeStates.SM)) {
    elementsToShow.push(NodeShape.SMALL_NODE_ELEMENT);
  } else if (nodeStates.includes(G6NodeStates.MD)) {
    elementsToShow.push(NodeShape.MEDIUM_NODE_ELEMENT, NodeShape.COMMENT_ICON);
  } else if (nodeStates.includes(G6NodeStates.LG)) {
    elementsToShow.push(NodeShape.LARGE_NODE_ELEMENT, NodeShape.COMMENT_ICON);
  }

  return { nodeStateToVisualize, elementsToShow };
};

export default getNodeStateVisualModifiers;
