import {
  BaseNodeProperty,
  NodeProperty,
  Norway_Travel_ShowAllClaimsNode,
} from '@riga-claims/atlas-models';
import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';
import BaseLayout from './BaseLayout';

class ShowAllClaimsLayout extends BaseLayout {
  constructor(node: Norway_Travel_ShowAllClaimsNode) {
    super(node);

    const { properties } = node;
    const { message, variableName, answerCanContainPersonalData } =
      BaseNodeProperty;
    const { claims } = NodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties[message].label,
          placeholder: baseNodeProperties[message].placeholder,
          ...properties[message],
        },
        this.mappedFields
      );

    const dataGroup = this.contentTab.addGroup('Data');

    dataGroup.addTextField(
      {
        fieldName: claims,
        label: 'Claims',
        placeholder: 'Get All Claims node output',
        ...node.properties[claims],
      },
      this.mappedFields
    );

    this.contentTab
      .addGroup(nodePropertiesGroups.editNode)
      .addTextField(
        {
          fieldName: variableName,
          label: baseNodeProperties[variableName].label,
          placeholder: baseNodeProperties[variableName].placeholder,
          ...properties[variableName],
        },
        this.mappedFields
      )
      .addCheckboxField(
        {
          fieldName: answerCanContainPersonalData,
          label: baseNodeProperties[answerCanContainPersonalData].label,
          title: baseNodeProperties[answerCanContainPersonalData].title,
          ...properties[answerCanContainPersonalData],
        },
        this.mappedFields
      );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.addAccessLogDataGroup({
      hasEnteredData: true,
    });
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default ShowAllClaimsLayout;
