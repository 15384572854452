import getEnvironment from './getEnvironment';
import { Environments } from '@/constants';

import { EditorConfigurationKeys } from '@/configuration/types';

import devConfig from '@/configuration/config';
import itestConfig from '@/configuration/config.itest';
import stestConfig from '@/configuration/config.stest';
import atestConfig from '@/configuration/config.atest';
import prodConfig from '@/configuration/config.prod';

const getConfig = (key: EditorConfigurationKeys): string => {
  const environment = getEnvironment();

  switch (environment) {
    case Environments.DEV:
      return devConfig[key];
    case Environments.ITEST:
      return itestConfig[key];
    case Environments.STEST:
      return stestConfig[key];
    case Environments.ATEST:
      return atestConfig[key];
    case Environments.PROD:
      return prodConfig[key];

    default:
      return '';
  }
};

export default getConfig;
