import {
  BaseNodeProperty,
  Finland_InputBankAccountNode,
  NodeProperty,
} from '@riga-claims/atlas-models';
import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';
import BaseLayout from './BaseLayout';

class InputBankAccountLayout extends BaseLayout {
  constructor(node: Finland_InputBankAccountNode) {
    super(node);

    const { properties } = node;
    const { variableName, message, validationMessage } = BaseNodeProperty;
    const { toUpperCase, removeWhiteSpaces, placeholder } = NodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties.message.label,
          placeholder: baseNodeProperties.message.placeholder,
          ...properties[message],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: placeholder,
          label: 'Placeholder',
          placeholder: 'Enter placeholder here',
          ...properties[placeholder],
        },
        this.mappedFields
      );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.validationTab.addGroup().addTextAreaField(
      {
        fieldName: validationMessage,
        label: baseNodeProperties[validationMessage].label,
        placeholder: baseNodeProperties[validationMessage].placeholder,
        ...properties[validationMessage],
      },
      this.mappedFields
    );

    this.propertiesTab
      .addGroup()
      .addCheckboxField(
        {
          fieldName: toUpperCase,
          label: 'To uppercase',
          ...properties[toUpperCase],
        },
        this.mappedFields
      )
      .addCheckboxField(
        {
          fieldName: removeWhiteSpaces,
          label: 'Remove white spaces',
          ...properties[removeWhiteSpaces],
        },
        this.mappedFields
      );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.addAccessLogDataGroup({
      hasEnteredData: true,
    });
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default InputBankAccountLayout;
