import {
  BaseNodeProperty,
  NodeProperty,
  Sweden_Motor_GetUserWorkshopRolesNode,
} from '@riga-claims/atlas-models';
import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class GetUserWorkshopRolesLayout extends BaseLayout {
  constructor(node: Sweden_Motor_GetUserWorkshopRolesNode) {
    super(node);

    const { workshopId } = NodeProperty;
    const { variableName } = BaseNodeProperty;

    this.contentTab.addGroup(nodePropertiesGroups.customerFacing).addTextField(
      {
        fieldName: workshopId,
        label: 'Workshop ID',
        placeholder: 'Workshop identifier',
        ...node.properties[workshopId],
      },
      this.mappedFields
    );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...node.properties[variableName],
      },
      this.mappedFields
    );

    this.addCommentField();
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default GetUserWorkshopRolesLayout;
