import { BaseNodeProperty, InputChoiceNode } from '@riga-claims/atlas-models';

import {
  baseNodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';

import BaseLayout from './BaseLayout';

class InputChoiceLayout extends BaseLayout {
  constructor(node: InputChoiceNode) {
    super(node);

    const { properties } = node;
    const { variableName, message } = BaseNodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties[message].label,
          placeholder: baseNodeProperties[message].placeholder,
          ...properties[message],
        },
        this.mappedFields
      );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.addAccessLogDataGroup({
      hasEnteredData: true,
    });
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default InputChoiceLayout;
