import {
  BaseNodeProperty,
  NodeProperty,
  Finland_Animal_AnimalDiagnosisNode,
  Finland_Animal_CatBreedsNode,
  Finland_Animal_DogBreedsNode,
  Finland_InputCountryNode,
  Finland_InputPostalCodeNode,
  Finland_PersonalInjury_BodyPartCodeNode,
  Finland_PersonalInjury_DiagnosisCodeNode,
  Finland_PersonalInjury_MedicineCodeNode,
  Finland_PersonalInjury_SportCodeNode,
  Norway_Expatriates_CountryNode,
  Norway_Travel_CountryNode,
  Sweden_InputCountryNode,
  Finland_PersonalInjury_TradeUnionCodeNode,
} from '@riga-claims/atlas-models';
import {
  baseNodeProperties,
  nodeProperties,
  nodePropertiesGroups,
} from '../nodeProperties/labels';
import BaseLayout from './BaseLayout';

class InputAutocompleteLayout extends BaseLayout {
  constructor(
    node:
      | Finland_Animal_AnimalDiagnosisNode
      | Finland_Animal_CatBreedsNode
      | Finland_Animal_DogBreedsNode
      | Finland_InputCountryNode
      | Finland_PersonalInjury_DiagnosisCodeNode
      | Finland_PersonalInjury_MedicineCodeNode
      | Finland_PersonalInjury_SportCodeNode
      | Finland_InputPostalCodeNode
      | Norway_Expatriates_CountryNode
      | Norway_Travel_CountryNode
      | Sweden_InputCountryNode
      | Finland_PersonalInjury_TradeUnionCodeNode
      | Finland_PersonalInjury_BodyPartCodeNode
  ) {
    super(node);

    const { properties } = node;
    const { noResultsFoundMessage, allowAnyValue, inputPlaceholder } =
      NodeProperty;
    const { variableName, message, validationMessage, validationRegex } =
      BaseNodeProperty;

    this.contentTab
      .addGroup(nodePropertiesGroups.customerFacing)
      .addTextAreaField(
        {
          fieldName: message,
          label: baseNodeProperties.message.label,
          placeholder: baseNodeProperties.message.placeholder,
          ...properties[message],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: inputPlaceholder,
          label: 'Placeholder (optional)',
          placeholder: 'Add placeholder for input field',
          ...properties[inputPlaceholder],
        },
        this.mappedFields
      );

    this.contentTab.addGroup(nodePropertiesGroups.editNode).addTextField(
      {
        fieldName: variableName,
        label: baseNodeProperties[variableName].label,
        placeholder: baseNodeProperties[variableName].placeholder,
        ...properties[variableName],
      },
      this.mappedFields
    );

    this.validationTab
      .addGroup(nodePropertiesGroups.noResultsFound)
      .addTextAreaField(
        {
          fieldName: noResultsFoundMessage,
          label: 'Error message',
          placeholder:
            'Add error message for customer in case no results are found',
          ...properties[noResultsFoundMessage],
        },
        this.mappedFields
      );
    this.validationTab
      .addGroup(nodePropertiesGroups.inputValidation)
      .addCheckboxField({
        fieldName: allowAnyValue,
        label: nodeProperties[allowAnyValue].label,
      })
      .addTextField(
        {
          fieldName: validationRegex,
          label: baseNodeProperties[validationRegex].label,
          placeholder: baseNodeProperties[validationRegex].placeholder,
          ...properties[validationRegex],
        },
        this.mappedFields
      )
      .addTextField(
        {
          fieldName: validationMessage,
          label: baseNodeProperties[validationMessage].label,
          ...properties[validationMessage],
        },
        this.mappedFields
      );

    this.addSummaryOverviewGroup();
    this.addCommentField();
    this.addAccessLogDataGroup({
      hasEnteredData: true,
    });
    this.mapCustomProps();
    this.mapRemainingProps();
  }
}

export default InputAutocompleteLayout;
